/* eslint-disable no-loop-func */
import { Component } from "react";
import "../../../styles/css/table.scss";
import "../../../App.css";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import Paper from "@material-ui/core/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { GridColDef, GridFilterModel, GridRowSelectionModel } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { DateTime } from "luxon";
import { DateHelper } from "../../../Common/DateHelper";
import AddIcon from '@mui/icons-material/Add';
import
Helper, {
    dateConverter,
    getBuildingTimeZoneByNodeId,
    getBuildingNameUsingFloorNodeId,
    getBuildingNodeIdUsingFloorNodeId,
    truncateString
} from "../../../Common/Helper";
import LoadingOverlay from "../../../Components/LoadingOverlay";
import Spinner from "../../../Components/Spinner";
import moment from "moment";
import { IPartialAppState, appContext } from "../../../AppContext";
import { RouterProps, RouteComponentProps, generatePath } from "react-router";
import IbssDialog from "../../../Components/uicomponents/IbssDialog";
import { SelectChangeEvent } from "@mui/material/Select";
import IbssDataGrid, { DataGridQueryResult, IIbssGridColDef, IDataQuery } from "../../../Components/uicomponents/IbssDataGrid";
import IbssActionButton, { IActionButton } from "../../../Components/uicomponents/IbssActionButton";
import IbssSvgIcon from "../../../Components/uicomponents/IbssSvgIcon";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid } from "@mui/material";
import IbssInputDropDown from "../../../Components/uicomponents/IbssInputDropDown";
import { IDispatch, IPropsFromState } from "../../../redux/Interfaces";
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import IbssPageHeader from "../../../Components/uicomponents/IbssPageHeader";
import IbssFormControl from "../../../Components/uicomponents/IbssFormControl";
import IbssTextField from "../../../Components/uicomponents/IbssTextField";
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IbssChip from "../../../Components/uicomponents/IbssChip";
import { BookingFilter, BookingFilter2 } from "../../../Providers.Api/Bookings/BookingRepository";
import { Space } from "../../../Providers.Api/Spaces/SpaceRepository";
import RefreshIcon from '@mui/icons-material/Refresh';
import customTheme from "../../../customTheme";
import { BuildingChangeReason, IbssComponent } from "../../../Components/IbssComponent";
import { AxiosError } from "axios";
import { ODataQuery } from "../../../Providers.Api/ODataQuery";
import * as GetV2BookingsEndpoint from "../../../Providers.Api/Bookings/GetV2BookingsEndpoint";
import React from "react";
import * as GetMyV2BookingsEndpoint from "../../../Providers.Api/Bookings/GetMyV2BookingsEndpoint";
import * as GetMyV2BookingsForOthersEndpoint from "../../../Providers.Api/Bookings/GetMyV2BookingsForOthersEndpoint";
import { KeyValue } from "../../../Common/KeyValue";

class Bookings extends IbssComponent<IProps, IState>
{
    private dataGridRef = React.createRef<IbssDataGrid>();
    private get dataGrid() { return this.dataGridRef.current; }
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() { return appContext().apiCache; }
    private buildingTimeZone: string = getBuildingTimeZoneByNodeId(this.appState.buildingId);
    private hasV2Rights = this.local.hasRight("API.Bookings.V2");
    private hasApproveRights = this.local.hasRight("API.Bookings.Approve");
    private hasRejectRights = this.local.hasRight("API.Bookings.Deny");

    private enableRefreshButtonAfterTimeOut(): void
    {
        setTimeout(() => {
            this.setState({ isRefreshButtonDisabled: false });
        }, 60000);
    }

    private async handleRefreshButtonClick(): Promise<void>
    {
        this.setState({ isRefreshButtonDisabled: true });
        await this.dataGrid?.refresh();
        this.enableRefreshButtonAfterTimeOut();
    }

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            getRightApiResponseErrorLoading: false,
            isFilter: false, 
            disableExport: false,
            isRefreshButtonDisabled: false,
            isCancelModal: false,
            isLoading: false,
            isLoadingToGetFilteredData: false,
            activePage: "0",
            show: false,
            buildingid: this.appState.buildingId,
            canExport: false,
            isCreateRight: false,
            isUpdateRight: false,
            isApproveRight: false,
            isDenyRight: false,
            selectedBookingToday: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingNext: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingYesterdayAuto: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedBookingNext7Day: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            selectedCustomFilterBooking: [{ id: 0, status: "", spaceId: "", ownerEmail: "" }],
            isCancel: false,
            isCheckIn: false,
            isCheckOut: false,
            isV2Rights: false,
            isCheckInOnBehalfOf: false,
            value: "",
            userList: [{ label: "", email: "" }],
            selectedUserBookedForObject: { label: "", email: "" },
            selectedUserBookedByObject: { label: "", email: "" },
            spaceOptions: [],
            selectedSpaceOption: null,
            filterMode: false,
            confirmModalshow: false,
            isCompleteLoading: false,
            isToday: false,
            daysFilter: "",
            bookingStartDate: DateHelper.now().toJSDate(),
            bookingEndDate: DateHelper.now().toJSDate(),
            dateDisabled: true,
            rowSelectionModel: [],
            cancelButtonEnabled: true,
            approveButtonEnabled: true,
            rejectButtonEnabled: true,
            checkInButtonEnabled: true,
            checkOutButtonEnabled: true,
            tableMessage: "",
            updates: false,
            cancelAllRowsEnable: true,
            modalStatus: "",
            isMyBookings: false,
            isMyBookingsForOthers: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.onBuildingIdChanged<IMatchParams>(params => params.buildingid, (buildingId, reason) => this.buildingIdChanged(buildingId, reason));
        this.setState({ isLoading: true });
        const { history } = this.props;
        const url = window.location.href;
        const path = new URL(url).pathname;
        await this.setStateAsync({ isMyBookings: path === "/flex-my-bookings", isMyBookingsForOthers: path === "/flex-my-bookings-for-others" });

        if (this.state.isMyBookings || this.state.isMyBookingsForOthers)
        {
            this.setState({
                daysFilter: this.labels.HubLabelThisWeek,
                bookingStartDate: DateHelper.now().startOf('week').toJSDate(),
                bookingEndDate: DateHelper.now().endOf('week').toJSDate(),
            });
        } else
        {
            this.setState({
                bookingStartDate: DateHelper.now().toJSDate(),
                bookingEndDate: DateHelper.now().toJSDate(),
                daysFilter: this.labels.HubLabelToday
            });
        }
        this.setState({ isToday: true });

        let buildingId = this.props.match.params.buildingid;
        if (buildingId == '0')
        {
            const buildings = await apis.getSpacesByFilterEnabledBuilding('Building', 1)
            buildingId = buildings.data[0].id
        }
        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: parseInt(buildingId) });
            if (this.props.match.params.tab)
            {
                this.setState({
                    activePage: this.props.match.params.tab,
                });
            }

            if (this.props.match.params.buildingid !== "0")
            {
                apis.getSpacesById(this.props.match.params.buildingid, "Building");
            }
            else
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }

            if (
                this.state.buildingid &&
                this.props.match.params.buildingid !== null &&
                this.state.buildingid !== parseInt(this.props.match.params.buildingid)
            )
            {
                history.push(
                    "/operational-services-bookings/" +
                    this.state.buildingid +
                    "/" +
                    this.state.activePage
                );
            }
            else
            {
                if (this.state.buildingid && this.props.match.params.filter)
                {
                    history.push(
                        "/operational-services-bookings/" +
                        this.state.buildingid +
                        "/" +
                        this.props.match.params.tab +
                        "/" +
                        this.props.match.params.filter
                    );
                } else if (this.state.buildingid)
                {
                    history.push(
                        "/operational-services-bookings/" +
                        this.state.buildingid +
                        "/" +
                        this.state.activePage
                    );
                } else
                {
                    history.push("/operational-services-buildings/0");
                }
            }

            this.setRights();
            await this.setBuilding(parseInt(buildingId));
        }
        else
        {
            this.setRights();
            await this.setBuilding(this.appState.buildingId);
        }
        this.setState({ isLoading: false });
    }

    public async buildingIdChanged(buildingId: number, reason: BuildingChangeReason): Promise<void>
    {
        if (reason == "BuildingSelectorChanged")
        {
            const path = generatePath(this.props.match.path, { buildingid: buildingId, tab: this.props.match.params.tab });
            this.props.history.push(path);
        }
        await this.setBuilding(buildingId);
    }

    private async setBuilding(buildingId: number): Promise<void>
    {
        // update state
        const building = this.local.getNodeData().Regions.flatMap(i => i.Buildings).find(i => i.Node_Id == buildingId);
        this.pageTitle = this.getPageTitle(building?.Name ?? "");
        await this.setStateAsync({ buildingid: buildingId });

        // load bookings
        await this.dataGrid?.refresh();
    }

    private getPageTitle(buildingName: string): string
    {
        if (this.state.isMyBookings)
        {
            return this.labels.HubMenumySchedule;
        }
        else if (this.state.isMyBookingsForOthers)
        {
            return this.labels.HubMenuBookingsForOthers;
        }
        else
        {
            return `${this.labels.HubLabelFacilityManagementText} ${buildingName}`;
        }
    }

    private setRights(): void
    {
        const res = this.local.getIbssRightList();
        this.setState({ canExport: this.local.hasRight("API.Bookings.Export") });
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Create") > -1
        )
        {
            this.setState({
                isCreateRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Update") > -1
        )
        {
            this.setState({
                isUpdateRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Delete") > -1
        )
        {
            this.setState({
                isCancel: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("V2") > -1
        )
        {
            this.setState({
                isV2Rights: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Approve") > -1
        )
        {
            this.setState({
                isApproveRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Bookings &&
            res.ADMINPORTAL.Bookings.indexOf("Deny") > -1
        )
        {
            this.setState({
                isDenyRight: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Spaces &&
            res.ADMINPORTAL.Spaces.indexOf("CheckIn") > -1
        )
        {
            this.setState({
                isCheckIn: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Spaces &&
            res.ADMINPORTAL.Spaces.indexOf("CheckOut") > -1
        )
        {
            this.setState({
                isCheckOut: true,
            });
        }
        if (
            res &&
            res.ADMINPORTAL &&
            res.ADMINPORTAL.Spaces &&
            res.ADMINPORTAL.Spaces.indexOf("CheckOut") > -1 &&
            res.ADMINPORTAL.Spaces.indexOf("CheckInOnBehalfOf") > -1
        )
        {
            this.setState({
                isCheckInOnBehalfOf: true,
            });
        }
    }

    private async dataQueryChanged(gridQuery: IDataQuery): Promise<DataGridQueryResult>
    {
        const startDate = DateTime.fromISO(dateConverter(this.state.bookingStartDate)).setZoneByNode(this.state.buildingid).startOf('day');
        const endDate = DateTime.fromISO(dateConverter(this.state.bookingEndDate)).setZoneByNode(this.state.buildingid).plus({ days: 1 }).startOf('day');
        const odataQuery = new ODataQuery({ top: gridQuery.pageSize, skipToken: gridQuery.skipToken, filter: gridQuery.odataFilter, orderBy: Helper.nameOf<GetV2BookingsEndpoint.Booking>("Booking_Start") });
        let bookingsView: BookingView[];

        if (this.state.isMyBookings)
        {
            odataQuery.filter = new BookingFilter
            ({
                baseFilter: odataQuery.filter,
                bookingStatus: (this.state.value == "" || this.state.value == "Any" ? "" : this.state.value),
                bookingStatusNot: (this.state.value == "" ? ['Cancelled', 'No Show', 'Auto Cancelled'] : []),
                createdBy: this.state.selectedUserBookedByObject.email,
                spaceName: this.state.selectedSpaceOption?.value ?? "",
            });

            const pagedBookings = await this.bookingService.getMyBookings(odataQuery, startDate, endDate);
            const email = this.local.getUserDetails().email.toLowerCase();
            const displayName = this.local.getUserDetails().displayName;
            bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsResponse(i, email, displayName));
            return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
        }
        else if (this.state.isMyBookingsForOthers)
        {
            odataQuery.filter = new BookingFilter
            ({
                baseFilter: odataQuery.filter,
                bookingStatus: (this.state.value == "" || this.state.value == "Any" ? "" : this.state.value),
                bookingOwnerEmail: this.state.selectedUserBookedForObject.email,
                spaceName: this.state.selectedSpaceOption?.value ?? "",
            });

            const email = this.local.getUserDetails().email.toLowerCase();
            const pagedBookings = await this.bookingService.getBookingsForOthers(odataQuery, email, startDate, endDate);
            bookingsView = pagedBookings.value.map(i => BookingView.fromMyBookingsForOthersResponse(i, email));
            return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
        }
        else
        {
            odataQuery.nodeId = this.state.buildingid;
            odataQuery.filter = new BookingFilter2
            ({
                baseFilter: odataQuery.filter,
                statuses: (this.state.value == "" || this.state.value == "Any" ? undefined : [ this.state.value ]),
                bookingOwnerEmail: this.state.selectedUserBookedForObject.email,
                createdBy: this.state.selectedUserBookedByObject.email,
                spaceName: this.state.selectedSpaceOption?.value ?? "",
            });

            const pagedBookings = await this.bookingService.getBookings(odataQuery, startDate, endDate);
            bookingsView = pagedBookings.value.map(i => BookingView.fromBookingsResponse(i));
            return new DataGridQueryResult(bookingsView, pagedBookings.skipToken);
        }
    }

    private fromDateToString(date: Date, type: "start" | "end"): string
    {
        if (type === "start")
        {
            return DateTime.fromISO(dateConverter(date)).setZone(getBuildingTimeZoneByNodeId(this.state.buildingid)).startOf('day').toUTC().toISO();
        }
        else
        {
            return DateTime.fromISO(dateConverter(date)).setZone(getBuildingTimeZoneByNodeId(this.state.buildingid)).endOf('day').toUTC().toISO();
        }
    }

    private filterButtonClicked(): void
    {
        this.setState({
            isFilter: !this.state.isFilter,
            filterMode: true,
        });
    }

    private bookedForChanged(event: React.SyntheticEvent<Element, Event>, newValue: IUserDetails): void
    {
        if (newValue)
        {
            this.setState({
                selectedUserBookedForObject: {
                    label: newValue.label,
                    email: newValue.email,
                },
            });
        } else
        {
            this.setState({ selectedUserBookedForObject: { label: "", email: "" } });
        }
    }

    private bookedByChanged(e: React.SyntheticEvent<Element, Event>, newValue: IUserDetails): void
    {
        if (newValue)
        {
            this.setState({
                selectedUserBookedByObject: {
                    label: newValue.label,
                    email: newValue.email,
                },
            });
        } else
        {
            this.setState({ selectedUserBookedByObject: { label: "", email: "" } });
        }
    }

    private bookedForOrBookedByChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({ [e.target.name]: e.target.value, updates: true } as any);
        if (e.target.value.length >= 3)
        {
            this.apiClient.users.getUsers(e.target.value)
                .then((res) =>
                {

                    const newArray =
                        res &&
                        res[0] &&
                        res.map(item =>
                        {
                            const newItem = { ...item, label: '' };
                            newItem.label = item.displayName ? item.displayName : item.email;
                            newItem.displayName = item.displayName
                                ? item.displayName
                                : item.email;
                            newItem.lastName = newItem.lastName ? newItem.lastName : "";
                            newItem.firstName = newItem.firstName ? newItem.firstName : "";

                            return newItem;
                        });
                    this.setState({
                        userList: newArray && newArray[0] ? newArray : [],
                    });
                })
                .catch((error) =>
                {
                    console.log(error)
                });
        } else
        {
            this.setState({
                userList: [],
            });
        }
    }

    private spaceOptionChanged(e: React.SyntheticEvent<Element, Event>, newValue: KeyValue<string, string> | null): void
    {
        this.setState({ selectedSpaceOption: newValue });
    }

    private async spaceOptionTextChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void>
    {
        const searchTerm = e.target.value;
        if (searchTerm.length < 3)
        {
            this.setState({ spaceOptions: [] });
            return;
        }

        const spaces = await this.apiCache.getSpacesByBuilding(this.state.buildingid);
        const filteredSpaces = spaces.filter(i => i.Space_Name.includes(searchTerm));
        const spaceOptions = filteredSpaces.map(i => ({ key: i.Space_Id, value: i.Space_Name, displayName: i.Space_Name }));
        this.setState({ spaceOptions: spaceOptions });
    }

    private statusChanged(event: SelectChangeEvent<string>): void
    {
        this.setState({ value: event.target.value });
    }

    private filterModalClosed(): void
    {
        this.setState({
            isFilter: false,
        });
    }

    private async filterModalOkayed(): Promise<void>
    {
        await this.dataGrid?.refresh();
        this.filterModalClosed();
        return;
    }

    private async dateRangeDropdownChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ daysFilter: e.target.value });

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().toJSDate(),
                    bookingEndDate: DateHelper.now().toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    bookingEndDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('week').toJSDate(),
                    bookingEndDate: DateHelper.now().endOf('week').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('week').plus({ days: 7 }).toJSDate(),
                    bookingEndDate: DateHelper.now().startOf('week').plus({ days: 13 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('month').toJSDate(),
                    bookingEndDate: DateHelper.now().endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('month').plus({ months: 1 }).toJSDate(),
                    bookingEndDate: DateHelper.now().plus({ months: 1 }).endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setState({ dateDisabled: false });
        }

        await this.dataGrid?.refresh();
    }

    private async dateChanged(e: Date, type: string): Promise<void>
    {

        if (type === "Start")
        {
            await this.setState({
                bookingStartDate: e,
                isCompleteLoading: true
            });
        }
        else
        {
            await this.setState({
                bookingEndDate: e,
                isCompleteLoading: true
            });
        }
        await this.dataGrid?.refresh();
        await this.setState({ isCompleteLoading: false });
    }

    private async exportClicked(): Promise<void>
    {
        //Disable the button
        this.setState({ disableExport: true });

        let bookingFilter = new BookingFilter(
            {
                bookingStatus: this.state.value,
                bookingOwnerEmail: this.state.selectedUserBookedForObject.email,
                createdBy: this.state.selectedUserBookedByObject.email,
                startDate: DateTime.fromISO(this.fromDateToString(this.state.bookingStartDate, 'start')),
                endDate: DateTime.fromISO(this.fromDateToString(this.state.bookingEndDate, 'end')),
            });

        try {
            await this.bookingService.download(this.state.buildingid, bookingFilter);
        }
         finally {
            //Enable the button after 60s
            setTimeout(() => {
                this.setState({ disableExport: false });
            }, 60000);
        }
    }

    private async checkOutOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.setState({ isCompleteLoading: true });
            const spaceId = data.Space_Id.split(';')[0];
            await this.apiClient.spaces.checkOutOfSpace(this.state.buildingid, spaceId, data.Booking_Owner_Email);
            this.setState({ isCompleteLoading: false });
            this.alert.show("", this.labels.HubLabelBookingCheckOutSuccessfully);
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private checkOutManyClicked(): void
    {
        this.setState({ isCompleteLoading: true });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<void>((resolve, reject) =>
            {
                try
                {
                    const spaceId = booking.Space_Id.split(';')[0];
                    this.apiClient.spaces.checkOutOfSpace(this.state.buildingid, spaceId, booking.Booking_Owner_Email)
                        .then(() => resolve());
                }
                catch
                {
                    return reject(new Error(`Failed to approve the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show("", this.labels.HubLabelBookingCheckOutSuccessfully);
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage();
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async approveOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.setState({ isCompleteLoading: true });
            await this.apiClient.bookings.approveBooking(data.Node_Id!, data.Booking_Id);
            this.setState({ isCompleteLoading: false });
            await this.dataGrid?.refreshPage();
        }
        catch (error)
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private approveManyClicked(): void
    {
        this.setState({ isCompleteLoading: true });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<string>((resolve, reject) =>
            {
                try
                {
                    this.apiClient
                        .bookings
                        .approveBooking(booking.Node_Id!, booking.id ?? "")
                        .then(i => resolve(i));
                }
                catch
                {
                    return reject(new Error(`Failed to approve the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelBookingsApprovedSuccesfullyMsg, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage();
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async rejectOneClicked(data: BookingView): Promise<void>  
    {
        try
        {
            this.setState({ isCompleteLoading: true });
            await this.apiClient.bookings.rejectBooking(this.state.buildingid, data.Booking_Id);
            this.alert.show("", this.labels.HubLabelBookingsRejectedSuccesfullyMsg);
            this.setState({ isCompleteLoading: false });
            await this.dataGrid?.refreshPage();
        }
        catch
        {
            this.setState({ isCompleteLoading: false });
        }
    }

    private rejectManyClicked(): void
    {
        this.setState({
            isCancelModal: false,
            isCompleteLoading: true,
        });

        const promises = this.state.rowSelectionModel.map(booking =>
        {
            return new Promise<string>((resolve, reject) =>
            {
                try
                {
                    this.apiClient
                        .bookings
                        .rejectBooking(this.state.buildingid, booking.id ?? "")
                        .then(i => resolve(i));
                    this.setState({ isCompleteLoading: false });

                }
                catch
                {
                    this.setState({ isCompleteLoading: false });
                    return reject(new Error(`Failed to reject the booking with ID ${booking.Booking_Id}`));
                }
            });
        });

        Promise.all(promises)
            .then(messages =>
            {
                this.alert.show("", this.labels.HubLabelBookingsRejectedSuccesfullyMsg);
                this.setState({ isCompleteLoading: false });
                this.dataGrid?.refreshPage();
            })
            .catch((errors: Error[]) =>
            {
                const messageHtml = `<ul>` + errors.map(i => `<li>${i.message}</li>`) + `</ul>`;
                this.alert.show(this.labels.HubLabelError, messageHtml, () => { }, { messageIsHtml: true });
                this.setState({ isCompleteLoading: false });
            });
    }

    private async cancelOneClicked(data: BookingView): Promise<void>  
    {
        this.setState({
            isCompleteLoading: true,
        })
        try
        {

            const _data = await this.bookingService.delete(this.state.buildingid, data.Booking_Id);
            if (_data)
            {
                this.setState({
                    isCompleteLoading: false,
                })
                await this.dataGrid?.refreshPage();
            } else
            {
                this.setState({
                    isCompleteLoading: false,
                })
            }
        } catch (error)
        {
            this.setState({
                isCompleteLoading: false,
            })
        }
    }

    private cancelManyClicked(): void
    {
        this.setState({
            isCancelModal: false,
            isCompleteLoading: true
        })
        let message = ""
        const promises = this.state.rowSelectionModel.map(resourceId =>
        {
            return this.bookingService.delete(this.state.buildingid, resourceId.id || "")
                .then((response) =>
                {
                    if (response)
                    {
                        message = response.Message
                        this.setState({
                            isCompleteLoading: false,
                        })
                        return Promise.resolve(response);
                    } else
                    {
                        this.setState({
                            isCompleteLoading: false,
                        })
                        return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
                    }
                })
        });
        Promise.all(promises)
            .then(responses =>
            {
                // All responses are okay, show the modal
                this.alert.show("", message);
                this.dataGrid?.refreshPage();
                this.setState({
                    isCompleteLoading: false,
                    rowSelectionModel: []
                })
            })
            .catch(error =>
            {
                this.setState({
                    isCompleteLoading: false,
                })
                console.log(error)
            }
            );

    }

    private selectionChanged(selection: GridRowSelectionModel): void
    {
        const pageOfRows = this.dataGrid?.state.rows as BookingView[];
        const selectedRows = pageOfRows.filter(row => selection.includes(row.Booking_Id))
        this.setState({ rowSelectionModel: selectedRows });

        const cancelRows: BookingView[] = [];
        const approveRows: BookingView[] = [];
        const rejectRows: BookingView[] = [];
        const checkInRows: BookingView[] = [];
        const checkOutRows: BookingView[] = [];
        const selectAllRows: BookingView[] = [];

        for (let i = 0; i <= selectedRows.length - 1; i++)
        {
            const rowStatus = selectedRows[i]["Booking_Status"];
            const rowApproved = selectedRows[i]["Booking_IsApproved"];
            if (this.state.isMyBookingsForOthers)
            {
                if (rowStatus === "New") 
                {
                    selectAllRows.push(selectedRows[i])
                }
            } else
            {
                if ((rowStatus === "New" && rowApproved === 0) ||
                    (rowStatus === "Amended" && rowApproved === 0) || //Awaiting Approval
                    (rowStatus === "New" && rowApproved === 3) ||
                    (rowStatus === "New" && rowApproved === 4) || //Approved
                    (rowStatus === "Late Checkin" && rowApproved === 3) ||
                    (rowStatus === "Late Checkin" && rowApproved === 4) || //Late Check in
                    (rowStatus === "Amended" && rowApproved === 3) ||
                    (rowStatus === "Amended" && rowApproved === 4) //Amended
                )
                {

                    cancelRows.push(selectedRows[i])
                }
            }

        }
        for (let j = 0; j <= selectedRows.length - 1; j++)
        {
            const rowStatus = selectedRows[j]["Booking_Status"];
            const rowApproved = selectedRows[j]["Booking_IsApproved"]
            if ((rowStatus === "New" && rowApproved === 0) ||
                (rowStatus === "Amended" && rowApproved === 0))
            {
                approveRows.push(selectedRows[j])
            }
        }
        for (let k = 0; k <= selectedRows.length - 1; k++)
        {
            const rowStatus = selectedRows[k]["Booking_Status"];
            const rowApproved = selectedRows[k]["Booking_IsApproved"]
            if ((rowStatus === "New" && rowApproved === 0) ||
                (rowStatus === "Amended" && rowApproved === 0))
            {
                rejectRows.push(selectedRows[k])
            }
        }
        for (let l = 0; l <= selectedRows.length - 1; l++)
        {
            const rowStatus = selectedRows[l]["Booking_Status"];
            const rowApproved = selectedRows[l]["Booking_IsApproved"];
            const rowEarlyCheckin = selectedRows[l]["Booking_Early_Checkin"];
            if ((((rowStatus === "New" || rowStatus === "Amended" || rowStatus === "Late Checkin") && rowApproved === 3) && DateTime.fromISO(rowEarlyCheckin) < DateTime.utc()) ||
                ((rowStatus === "New" || rowStatus === "Amended" || rowStatus === "Late Checkin") && rowApproved === 4 && DateTime.fromISO(rowEarlyCheckin) < DateTime.utc()))
            {
                checkInRows.push(selectedRows[l])
            }
        }
        for (let m = 0; m <= selectedRows.length - 1; m++)
        {
            const rowStatus = selectedRows[m]["Booking_Status"];
            const rowApproved = selectedRows[m]["Booking_IsApproved"];

            if ((rowStatus === "In Progress" && rowApproved === 3) ||
                (rowStatus === "In Progress" && rowApproved === 4) ||
                (rowStatus === "Amended" && selectedRows[m]["Booking_IsCheckedIn"] === 1) ||
                (rowStatus === "Early Check In"))
            {
                checkOutRows.push(selectedRows[m]);
            }
        }
        if (selectedRows.length <= 0 && cancelRows.length <= 0)
        {
            this.setState({
                cancelButtonEnabled: true,
            })
        }
        else if (selectedRows.length === cancelRows.length && this.state.isCancel)
        {
            this.setState({
                cancelButtonEnabled: false
            })
        }
        else
        {
            this.setState({
                cancelButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && approveRows.length <= 0)
        {
            this.setState({
                approveButtonEnabled: true,
            })
        } else if (selectedRows.length === approveRows.length && this.state.isApproveRight)
        {
            this.setState({
                approveButtonEnabled: false
            })
        } else
        {
            this.setState({
                approveButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && rejectRows.length <= 0 && this.state.isDenyRight)
        {
            this.setState({
                rejectButtonEnabled: true,
            })
        } else if (selectedRows.length === rejectRows.length && this.state.isDenyRight)
        {
            this.setState({
                rejectButtonEnabled: false
            })
        } else
        {
            this.setState({
                rejectButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && checkInRows.length <= 0)
        {
            this.setState({
                checkInButtonEnabled: true,
            })
        } else if (selectedRows.length === checkInRows.length && this.state.isCheckIn)
        {
            this.setState({
                checkInButtonEnabled: false
            })
        } else
        {
            this.setState({
                checkInButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && checkOutRows.length <= 0)
        {
            this.setState({
                checkOutButtonEnabled: true,
            })
        } else if (selectedRows.length === checkOutRows.length && this.state.isCheckOut)
        {
            this.setState({
                checkOutButtonEnabled: false
            })
        } else
        {
            this.setState({
                checkOutButtonEnabled: true
            })
        }
        if (selectedRows.length <= 0 && selectAllRows.length <= 0)
        {
            this.setState({
                cancelAllRowsEnable: true,
            })
        } else if (selectedRows.length === selectAllRows.length && this.state.isCancel)
        {
            this.setState({
                cancelAllRowsEnable: false
            })
        } else
        {
            this.setState({
                cancelAllRowsEnable: true
            })
        }
    }

    public createBookingClicked(type: string, param?: BookingView): void
    {
        const { history } = this.props;
        if (type === "add")
        {
            history.push("/flex-find-a-space");
        }
        else
        {
            history.push(`/operational-services-bookings/${this.state.buildingid}/bookings/${param?.Booking_Id}/${param?.Space_Id}`);
        }
    }

    private createBookingForMeClicked(param?: BookingView): void
    {
        let bookingSpaceId: string | undefined = "";
        if (param?.Space_Id.includes(";"))
        {
            const space_id = param?.Space_Id.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = param?.Space_Id

        }
        const { history } = this.props;
        history.push(`/flex-my-bookings/${getBuildingNodeIdUsingFloorNodeId(param?.Node_Id)}/mybooking/${param?.Booking_Id}/${bookingSpaceId}`);
    }

    private createBookingForOthersClicked(param?: BookingView): void
    {
        let bookingSpaceId: string | undefined = "";
        if (param?.Space_Id.includes(";"))
        {
            const space_id = param?.Space_Id.split(";");
            bookingSpaceId = space_id[0];
        } else
        {
            bookingSpaceId = param?.Space_Id

        }
        const { history } = this.props;
        history.push(`/flex-my-bookings-for-others/${getBuildingNodeIdUsingFloorNodeId(param?.Node_Id)}/mybookingother/${param?.Booking_Id}/${bookingSpaceId}`);
    }

    private getDateAndTime(row: BookingView, type: string): string
    {
        const buildingNodeId = getBuildingNodeIdUsingFloorNodeId(row.Node_Id);
        const getBuilding = getBuildingTimeZoneByNodeId(buildingNodeId);
        const localNow = DateTime.local();
        const currentZone = localNow.zoneName;
        if (type === 'start')
        {
            return DateTime.fromISO(row.Booking_Start, { zone: getBuilding }).toLocaleDateTimeString();
        }
        else if (type === "end")
        {
            return DateTime.fromISO(row.Booking_End, { zone: getBuilding }).toLocaleDateTimeString();
        } else
        {
            if (currentZone === getBuilding) 
            {
                return this.labels.HubLabelLocalTime
            }
            else 
            {
                // Get the current time in the specified timezone
                const dateTime = DateTime.local().setZone(getBuilding);

                const utcOffsetMinutes = dateTime.offset;

                const utcOffsetHours = utcOffsetMinutes / 60;

                return `UTC ${utcOffsetHours > 0 ? "+" + utcOffsetHours : utcOffsetHours}`
            }
        }
    }

    public render(): JSX.Element
    {
        // for the server field names, there is an assumption that all 3 endpoints (my bookings, my bookings for others and one lens bookins) use the same field names
        const dataTodayColumn: IIbssGridColDef<BookingView>[] = [
            {
                headerName: this.labels.HubLabelBookingName,
                field: "Booking_Name",
                minWidth: 140,
                flex: 1,
                filterable: false,
            },
            {
                headerName: this.labels.HubLabelBookingStart,
                field: "Booking_Start",
                minWidth: 140,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'start'),
                //serverField: Helper.nameOf<GetV2BookingsEndpoint.Booking>("Booking_Start"),
            },
            {
                headerName: this.labels.HubLabelBookingEnd,
                field: "Booking_End",
                minWidth: 140,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'end'),
            },
            {
                headerName: this.labels.HubLabelTimeZone,
                field: "timeZone",
                minWidth: 140,
                flex: 1,
                valueGetter: (params) => this.getDateAndTime(params.row, 'timezone'),
            },
            {
                headerName: this.labels.HubLabelSpace,
                field: "Space_Name",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBookedFor,
                field: "Booking_Owner_Name",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelBookedBy,
                field: "_CreatedBy",
                minWidth: 140,
                flex: 1,
            },
            {
                headerName: this.labels.HubLabelAction,
                minWidth: 120,
                field: "",
                flex: 1,
                renderCell: (params) =>
                    <>
                        {this.state.isMyBookings && this.renderCheckInCheckOutActionButton(params.row)}
                        {this.state.isMyBookingsForOthers && this.renderCancelAllActionButton(params.row)}
                        {!this.state.isMyBookings && !this.state.isMyBookingsForOthers && this.renderActionButtonFromBooking(params.row)}
                    </>
            },
            {
                headerName: "Status",
                minWidth: 120,
                flex: 1,
                field: "status",
                renderCell: (params) => (this.state.isMyBookingsForOthers || this.state.isMyBookings) ? this.flexBookingStatus(params.row) : this.renderBookingOverAllStatus(params.row),
                filterable: false,
                sortable: false
            },
        ];

        if (this.state.isMyBookings)
        {
            dataTodayColumn.unshift({
                headerName: this.labels.HubLabelBuilding,
                field: "Building_Name",
                minWidth: 140,
                flex: 1,
            })
        }

        if (this.state.isMyBookingsForOthers)
        {
            dataTodayColumn.unshift({
                headerName: this.labels.HubLabelBuilding,
                field: "Building_Name",
                minWidth: 140,
                flex: 1
            })
        }

        let filterStatusOption = [
            { label: this.labels.HubLabelApproved, value: "Approved" },
            { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
            {
                label: this.labels.HubLabelAutoCancelledStatus,
                value: "Auto Cancelled",
            },
            { label: this.labels.HubLabelCancelled, value: "Cancelled" },
            { label: this.labels.HubLabelCompleted, value: "Completed" },
            { label: this.labels.HubLabelamended, value: "Amended" },
            { label: this.labels.HubLabelNew, value: "New" },
            { label: this.labels.HublabelEarlyCheckIn, value: "Early Check In" },
            { label: this.labels.HubLabelInProgress, value: "In Progress" },
            { label: this.labels.HublabelLateCheckIn, value: "Late Checkin" },
            { label: this.labels.HubLabelNoShow, value: "No Show" },
        ].sort((a, b) =>
        {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })

        filterStatusOption = [{ label: this.labels.HubLabelAny, value: "Any" }, ...filterStatusOption]

        const actions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelButtonEnabled,
                onClick: () => this.setState({
                    isCancelModal: true,
                    modalStatus: "cancel"
                })
            },
            {
                label: this.labels.HubLabelReject,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.rejectButtonEnabled || !(this.hasV2Rights && this.hasRejectRights),
                onClick: () => this.setState({
                    isCancelModal: true,
                    modalStatus: "reject"
                })
            },
            {
                label: this.labels.HubLabelApprove,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.approveButtonEnabled || !(this.hasV2Rights && this.hasApproveRights),
                onClick: () => this.approveManyClicked()
            },
            {
                label: this.labels.HubButtonAdd,
                className: "addIcon",
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: this.state.isCreateRight ? false : true,
                onClick: () => this.createBookingClicked("add"),
            }
        ]

        const checkInActions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {

                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelButtonEnabled,
                onClick: () => this.setState({
                    isCancelModal: true,
                    modalStatus: "cancel"
                })
            },
            {
                label: this.labels.HubButtonCheckOut,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: this.state.checkOutButtonEnabled,
                onClick: () => this.checkOutManyClicked()
            },
        ]

        const otherActions: IActionButton[] = [
            {
                label: this.labels.funcRefreshBookings_S,
                icon: (
                    <IbssSvgIcon sx={{ color: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>
                        <RefreshIcon />
                    </IbssSvgIcon>),
                color: "inherit",
                labelColor: this.appState.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate,
                onClick: () => this.handleRefreshButtonClick(),
                disabled: this.state.isRefreshButtonDisabled
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: this.state.cancelAllRowsEnable,
                onClick: () => this.setState({
                    isCancelModal: true,
                    modalStatus: "cancel"
                })
            }
        ]


        return (
            <>
                <div className="">
                    <IbssDialog
                        onClose={() => this.filterModalClosed()}
                        open={this.state.isFilter}
                        header={this.labels.HubLabelFilter}
                        fullWidth
                        content={
                            <>
                                <div className="row">
                                    <IbssInputDropDown
                                        inputLabel={this.labels.HubLabelStatus}
                                        options={filterStatusOption}
                                        value={this.state.value}
                                        id={"statusSelection"}
                                        onChange={(e: SelectChangeEvent<string>) => this.statusChanged(e)}
                                        fullWidth
                                    />
                                </div>

                                {!this.state.isMyBookings &&
                                    <div className="row">
                                        <div className="form-input-box">
                                            <label className="form-input-box-label visit-filter-label">
                                                {this.labels.HubLabelBookedFor}
                                            </label>
                                            <Autocomplete
                                                freeSolo
                                                className="form-input-box-autocomplete auto-text"
                                                options={this.state.userList}
                                                PaperComponent={({ children }) => (
                                                    <Paper
                                                        style={{
                                                            fontSize: "14px",
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {children}
                                                    </Paper>
                                                )}
                                                value={this.state.selectedUserBookedForObject.label}
                                                onChange={(e, newValue) => this.bookedForChanged(e, newValue as IUserDetails)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="input-box model-textbox"
                                                        name="selectedUser"
                                                        id="selectedUser"
                                                        placeholder="&#8230;"
                                                        value={this.state.selectedUserBookedForObject.label}
                                                        onBlur={(e) =>
                                                        {
                                                            this.bookedForOrBookedByChanged(e);
                                                        }}
                                                        onChange={(e) =>
                                                        {
                                                            this.bookedForOrBookedByChanged(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                }

                                {!this.state.isMyBookingsForOthers &&
                                    <div className="row">
                                        <div className="form-input-box">
                                            <label className="form-input-box-label visit-filter-label">
                                                {this.labels.HubLabelBookedBy}
                                            </label>
                                            <Autocomplete
                                                freeSolo
                                                className="form-input-box-autocomplete auto-text"
                                                options={this.state.userList}
                                                PaperComponent={({ children }) => (
                                                    <Paper
                                                        style={{
                                                            fontSize: "14px",
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {children}
                                                    </Paper>
                                                )}
                                                value={this.state.selectedUserBookedByObject.label}
                                                onChange={(e, newValue) => this.bookedByChanged(e, newValue as IUserDetails)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="input-box model-textbox"
                                                        name="selectedUserBookedBy"
                                                        id="selectedUserBookedBy"
                                                        placeholder="&#8230;"
                                                        value={this.state.selectedUserBookedByObject.label}
                                                        onBlur={(e) =>
                                                        {
                                                            this.bookedForOrBookedByChanged(e);
                                                        }}
                                                        onChange={(e) =>
                                                        {
                                                            this.bookedForOrBookedByChanged(e);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="form-input-box">
                                        <label className="form-input-box-label visit-filter-label">
                                            {this.labels.HubLabelSpace}
                                        </label>
                                        <Autocomplete
                                            freeSolo
                                            className="form-input-box-autocomplete auto-text"
                                            options={this.state.spaceOptions}
                                            getOptionLabel={i => (i as KeyValue<string, string>).value}
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Source Sans Pro",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            value={this.state.selectedSpaceOption}
                                            onChange={(e, newValue) => this.spaceOptionChanged(e, newValue as KeyValue<string, string>)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="input-box model-textbox"
                                                    name="selectedSpaceOption"
                                                    id="selectedSpaceOption"
                                                    placeholder="&#8230;"
                                                    value={this.state.selectedSpaceOption}
                                                    onBlur={(e) =>
                                                    {
                                                        this.spaceOptionTextChanged(e);
                                                    }}
                                                    onChange={(e) =>
                                                    {
                                                        this.spaceOptionTextChanged(e);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalClosed()}
                                    color="secondary"
                                    variant="outlined"
                                    className="mr-2"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    className="ml-2"
                                    onClick={() => this.filterModalOkayed()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <IbssDialog
                        onClose={() => this.setState({
                            isCancelModal: false,
                        })}
                        open={this.state.isCancelModal}
                        header={this.labels.HubLabelModalConfirmTitle}
                        fullWidth
                        content={
                            <>
                                {
                                    this.state.modalStatus === "cancel" ?
                                        <p>{this.labels.HubLabelCancelAllMessage}</p> :
                                        <p>{this.labels.HubLabelRejectAllMessage}</p>
                                }
                            </>
                        }
                        footer={
                            <>
                                <IbssButtonRedo color="secondary" variant="contained"
                                    className="mr-2"
                                    onClick={() => this.setState({
                                        isCancelModal: !this.state.isCancelModal,
                                    })}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo color="primary" variant="contained"
                                    className="ml-2"
                                    onClick={() => this.state.modalStatus === "cancel" ?
                                        this.cancelManyClicked() : this.rejectManyClicked()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {this.state.isLoading ? <Spinner /> :
                        <div className="">
                            <div className="rightPanel-main-content">
                                <div className="table-panel">
                                    <IbssPageHeader
                                        pageTitle={this.state.isMyBookingsForOthers ? this.labels.HubMenuBookingsForOthers : this.labels.HubLabelBookingsOverview}
                                        daysFilter={this.state.daysFilter}
                                        todayChanged={(e) => this.dateRangeDropdownChanged(e)}
                                        startDate={this.state.bookingStartDate}
                                        endDate={this.state.bookingEndDate}
                                        dateDisabled={this.state.dateDisabled}
                                        dateChanged={(event, value) => this.dateChanged(event, value)}
                                        additionalDateFilterOptions={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                    />
                                    <Grid container rowSpacing={1} sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
                                        <Grid item md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                                                sx={{
                                                    '& > :not(style)': { mr: 2, my: 1, alignItems: "center" },
                                                }}
                                            >
                                                <IbssButtonRedo variant="contained" onClick={() => this.filterButtonClicked()}>{this.labels.HubLabelFilter}</IbssButtonRedo>
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'right', my: 1 }}>
                                            {this.state.canExport && !(this.state.isMyBookings || this.state.isMyBookingsForOthers) &&
                                                <IbssButtonRedo variant="contained" color="secondary" onClick={() => this.exportClicked()} disabled={this.state.disableExport}>{this.labels.HubButtonExport}</IbssButtonRedo>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                    {this.state.isMyBookingsForOthers ?
                                        <IbssActionButton buttons={otherActions} />
                                        :
                                        <IbssActionButton buttons={this.state.isMyBookings ? checkInActions : actions} />
                                    }
                                    </Box>
                                    {this.state.isCompleteLoading &&
                                        <Spinner />
                                    }
                                    <Box sx={{ mt: 1 }}>
                                        <IbssDataGrid
                                            ref={this.dataGridRef}
                                            checkboxSelection
                                            paginationMode="server"
                                            onDataQueryChange={i => this.dataQueryChanged(i)}
                                            rows={[]}
                                            columns={dataTodayColumn}
                                            onRowSelectionModelChange={e => this.selectionChanged(e)}
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>}
                </div>
            </>
        );
    }

    private renderBookingOverAllStatus(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;
        if ((bookingStatus === "New" && bookingApproval === 0) ||
            (bookingStatus === "Amended" && bookingApproval === 0))
        {
            return <IbssChip label={this.labels.HubTabAwaitingApproval} sx={{
                backgroundColor: 'var(--ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "New" && bookingApproval === 3) ||
            (bookingStatus === "New" && bookingApproval === 4))
        {
            return <IbssChip label={this.labels.HubLabelApproved} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Early Check In" && bookingApproval === 3) ||
            (bookingStatus === "Early Check In" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "Early Check In"))
        {
            return <IbssChip label={this.labels.HublabelEarlyCheckInText} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Late Checkin" && bookingApproval === 3) ||
            (bookingStatus === "Late Checkin" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "Late Checkin"))
        {
            return <IbssChip label={this.labels.HublabelLateCheckIn} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "Amended" && bookingApproval === 3) ||
            (bookingStatus === "Amended" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "Amended"))
        {
            return <IbssChip label={this.labels.HubLabelamended} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if ((bookingStatus === "In Progress" && bookingApproval === 3) ||
            (bookingStatus === "In Progress" && bookingApproval === 4) ||
            (this.state.isMyBookings && bookingStatus === "In Progress"))
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-mid-tone)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelCancelled} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Auto Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelAutoCancelledStatus} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Completed")
        {
            return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "No Show")
        {
            return <IbssChip label={this.labels.HubLabelNoShow} sx={{
                backgroundColor: 'var(ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (this.state.isMyBookings && bookingStatus === "New")
        {
            return <IbssChip label={this.labels.HubLabelNew} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    private flexBookingStatus(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        if (bookingStatus === "New")
        {
            return <IbssChip label={this.labels.HubLabelNew} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Amended")
        {
            return <IbssChip label={this.labels.HubLabelamended} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Early Check In")
        {
            return <IbssChip label={this.labels.HublabelEarlyCheckInText} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "Late Checkin")
        {
            return <IbssChip label={this.labels.HublabelLateCheckIn} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(ui-text)",
            }} />;
        } else if (bookingStatus === "In Progress")
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-mid-tone)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "No Show")
        {
            return <IbssChip label={this.labels.HubLabelNoShow} sx={{
                backgroundColor: 'var(ui-error-pastel)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Auto Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelAutoCancelledStatus} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(ui-text)",
            }} />;
        }
        else if (bookingStatus === "Completed")
        {
            return <IbssChip label={this.labels.HubLabelCompleted} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }

    private renderActionButton(row: BookingView, disabled: boolean, hanldeFunction: () => Promise<void>, label: string): JSX.Element
    {
        return (
            <>
                <IbssButtonRedo tabIndex={0} disabled={!disabled} onClick={() => hanldeFunction()} className="text-link">{label}</IbssButtonRedo>
                {this.state.isUpdateRight ?
                    <IbssSvgIcon tabIndex={0} className="pointer" onClick={() => this.state.isMyBookings ? this.createBookingForMeClicked(row) : this.createBookingClicked("", row)}>
                        <CreateIcon />
                    </IbssSvgIcon> : null
                }
            </>
        )
    }

    private renderActionButtonForOthers(row: BookingView, disabled: boolean, hanldeFunction: () => Promise<void>, label: string): JSX.Element
    {
        return (
            <>
                <IbssButtonRedo disabled={!disabled} onClick={() => hanldeFunction()} className="text-link">{label}</IbssButtonRedo>
                {this.state.isUpdateRight ?
                    <IbssSvgIcon className="pointer" onClick={() => this.createBookingForOthersClicked(row)}>
                        <CreateIcon />
                    </IbssSvgIcon> : <p>-</p>
                }
            </>
        )
    }

    private renderActionButtonFromBooking(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;

        if ((bookingStatus === 'In Progress' && bookingApproval === 3) || (bookingStatus === "In Progress" && bookingApproval === 4) || (bookingStatus === "Early Check In")) 
        {
            return this.renderActionButton(row, this.state.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        } else if ((bookingStatus === 'New' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 0)) 
        {
            if (this.state.isApproveRight)
            {
                return this.renderActionButton(row, this.state.isApproveRight, () => this.approveOneClicked(row), this.labels.HubLabelApprove)
            } else if (this.state.isDenyRight) 
            {
                return this.renderActionButton(row, this.state.isDenyRight, () => this.rejectOneClicked(row), this.labels.HubLabelReject)
            }
        }
        else if ((bookingStatus === 'Amended' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 3) ||
            (bookingStatus === "New" && bookingApproval === 3) || (bookingStatus === "New" && bookingApproval === 4))
        {
            return this.renderActionButton(row, this.state.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else 
        {
            return (
                <>
                    {/* <IbssButtonRedo className="text-link">-</IbssButtonRedo> */}
                    {this.state.isUpdateRight ?
                        <IbssSvgIcon className="pointer" onClick={() => this.createBookingClicked("", row)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssSvgIcon> : null
                    }
                </>
            )
        }
    }

    private renderCheckInCheckOutActionButton(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        const bookingApproval = row.Booking_IsApproved;

        if ((bookingStatus === 'In Progress' && bookingApproval === 3) || (bookingStatus === "In Progress" && bookingApproval === 4) || (bookingStatus === "Early Check In")) 
        {
            return this.renderActionButton(row, this.state.isCheckOut, () => this.checkOutOneClicked(row), this.labels.HubButtonCheckOut)
        } else if ((bookingStatus === 'Amended' && bookingApproval === 0) || (bookingStatus === "Amended" && bookingApproval === 3))
        {
            return this.renderActionButton(row, this.state.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else 
        {
            return (
                <>
                    {this.state.isUpdateRight ?
                        <IbssSvgIcon className="pointer" onClick={() => this.createBookingForMeClicked(row)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssSvgIcon> : null
                    }
                </>
            )
        }
    }

    private renderCancelAllActionButton(row: BookingView): React.ReactNode
    {
        const bookingStatus = row.Booking_Status;
        if (bookingStatus === 'New')
        {
            return this.renderActionButtonForOthers(row, this.state.isCancel, () => this.cancelOneClicked(row), this.labels.HubButtonCancel)
        }
        else 
        {
            return (
                <>
                    {this.state.isUpdateRight ?
                        <IbssSvgIcon className="pointer" onClick={() => this.createBookingForOthersClicked(row)}>
                            {bookingStatus === "Completed" || bookingStatus === "No Show" || bookingStatus === "Auto Cancelled" || bookingStatus === "Cancelled" ? <RemoveRedEyeIcon /> : <CreateIcon />}
                        </IbssSvgIcon> : null
                    }
                </>
            )
        }
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
    tab: string;
    filter: string;
}

interface IState
{
    getRightApiResponseErrorLoading: boolean;
    isFilter: boolean;
    isLoading: boolean;
    disableExport: boolean;
    isRefreshButtonDisabled: boolean;
    isLoadingToGetFilteredData: boolean;
    activePage: string;
    show: boolean;
    buildingid: number;
    canExport: boolean;
    isCreateRight: boolean;
    isUpdateRight: boolean;
    selectedBookingToday: IBooking[];
    selectedBookingNext: IBooking[];
    selectedBookingYesterdayAuto: IBooking[];
    selectedBookingNext7Day: IBooking[];
    selectedCustomFilterBooking: IBooking[];
    isCancel: boolean;
    isCheckIn: boolean;
    isCheckOut: boolean;
    isV2Rights: boolean;
    isApproveRight: boolean;
    isDenyRight: boolean;
    isCheckInOnBehalfOf: boolean;
    value: string;
    userList: ILabelEmail[];
    selectedUserBookedForObject: ILabelEmail;
    selectedUserBookedByObject: ILabelEmail;
    spaceOptions: KeyValue<string, string>[];
    selectedSpaceOption: (KeyValue<string, string> | null);
    filterMode: boolean;
    confirmModalshow: boolean;
    isCompleteLoading: boolean;
    isToday: boolean;
    daysFilter: string;
    bookingStartDate: Date;
    bookingEndDate: Date;
    dateDisabled: boolean;
    rowSelectionModel: BookingView[];
    cancelButtonEnabled: boolean;
    approveButtonEnabled: boolean;
    rejectButtonEnabled: boolean;
    tableMessage: string;
    updates: boolean;
    checkInButtonEnabled: boolean;
    checkOutButtonEnabled: boolean;
    cancelAllRowsEnable: boolean;
    isCancelModal: boolean;
    modalStatus: string;
    isMyBookings: boolean;
    isMyBookingsForOthers: boolean;
}

interface IBooking
{
    id: number;
    status: string;
    spaceId: string;
    ownerEmail: string;
}

interface ILabelEmail
{
    label?: string;
    email: string;
}

interface IFilterObject
{
    buildingId: number;
    statusValue: string;
    bookedFor: string;
    bookedBy: string;
}

interface IUserDetails
{
    label?: string;
    companyName: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}

class BookingView
{
    public id = "";
    public Building_Name = "";
    public Node_Id = 0;
    public Space_Id = "";
    public Space_Name = "";
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Start = "";
    public Booking_Early_Checkin = "";
    public Booking_End = "";
    public Booking_Status = "";
    public Booking_IsActive = 0;
    public Booking_IsApproved = 0;
    public Booking_IsCheckedIn? = 0;
    public Booking_Owner_Email = "";
    public Booking_Owner_Name = "";
    public _CreatedAt = "";
    public _CreatedBy = "";

    public static fromMyBookingsResponse(booking: GetMyV2BookingsEndpoint.Booking, bookingOwnerEmail: string, defaultBookingOwnerName: string): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: 1,
            Booking_IsApproved: 4,
            Booking_IsCheckedIn: booking.Booking_Participant_CheckedIn,
            Booking_Owner_Email: bookingOwnerEmail,
            Booking_Owner_Name: (booking.Booking_Owner_Name ? booking.Booking_Owner_Name : defaultBookingOwnerName),
            _CreatedAt: booking._CreatedAt.toISO(),
            _CreatedBy: booking.CreatedBy,
        };
    }

    public static fromMyBookingsForOthersResponse(booking: GetMyV2BookingsForOthersEndpoint.Booking, bookingOwnerEmail: string): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: 1,
            Booking_IsApproved: 4,
            Booking_IsCheckedIn: booking.Booking_IsCheckedIn,
            Booking_Owner_Email: bookingOwnerEmail,
            Booking_Owner_Name: booking.Booking_Owner_Name,
            _CreatedAt: booking._CreatedAt.toISO(),
            _CreatedBy: booking._CreatedBy,
        };
    }

    public static fromBookingsResponse(booking: GetV2BookingsEndpoint.Booking): BookingView
    {
        return {
            id: booking.Booking_Id,
            Building_Name: getBuildingNameUsingFloorNodeId(booking.Node_Id),
            Node_Id: booking.Node_Id,
            Space_Id: booking.Space_Id,
            Space_Name: booking.Space_Name,
            Booking_Id: booking.Booking_Id,
            Booking_Name: booking.Booking_Name,
            Booking_Start: booking.Booking_Start.toISO(),
            Booking_Early_Checkin: booking.Booking_Early_Checkin.toISO(),
            Booking_End: booking.Booking_End.toISO(),
            Booking_Status: booking.Booking_Status,
            Booking_IsActive: booking.Booking_IsActive,
            Booking_IsApproved: booking.Booking_IsApproved,
            Booking_IsCheckedIn: booking.Booking_IsCheckedIn,
            Booking_Owner_Email: booking.Booking_Owner_Email,
            Booking_Owner_Name: booking.Booking_Owner_Name,
            _CreatedAt: booking._CreatedAt.toISO(),
            _CreatedBy: booking._CreatedBy,
        };
    }
}

export default connect(mapStateToProps)(Bookings);
