import { FormControl, Grid, LinearProgress, Skeleton, TextField } from "@mui/material";
import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import { appContext } from "../../../../AppContext";
import { Card, Box } from '@mui/material';
import IbssFormControl from "../../../../Components/uicomponents/IbssFormControl";
import IbssInputDropDown from "../../../../Components/uicomponents/IbssInputDropDown";
import IbssTextField from "../../../../Components/uicomponents/IbssTextField";
import { IFloor, INode, ISearchConfigItem, IZone } from "../../../../Providers.Api/Models";
import Helper, { getAllBuildingsData, getFloorNameUsingFloorAndBuildingId, getSpaceTypesByNodeId, getWorkSpaceTypesByNodeId } from "../../../../Common/Helper";
import apis from "../../../../Providers.Api/apis";
import IbssTimePicker from "../../../../Components/uicomponents/IbssTimePicker";
import { IBuildingConfig } from "../../../../Common/ConfigHelper";
import { IUserPreferences, IUserPreferencesNode } from "../../../../Providers.Api/UserPreferenceRepository";
import IbssDatePicker from "../../../../Components/uicomponents/Datepicker/IbssDatePicker";
import IbssRadioButton from "../../../../Components/uicomponents/IbssRadioButton";
import IbssCheckBox from "../../../../Components/uicomponents/IbssCheckBox";
import IbssSwitchLabel from "../../../../Components/uicomponents/IbssSwitchLabel";
import IbssButton from "../../../../Components/uicomponents/IbssButton";
import Spinner from "../../../../Components/Spinner";
import './RecurringBooking.scss'
import RecurringSpaceCard from "../../../../Components/SpaceCard/RecurringSpaceCard";
import BookForSomeoneElse, { IOnBehalfOf } from "./BookForSomeoneElse";
import AddAttendees, { IAttendee } from "./AddAttendees";
import AddCostCodes from "./AddCostCodes";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/uicomponents/IbssSvgIcon";
import IbssButtonRedo from "../../../../Components/uicomponents/IbssButton";
import { Modal } from 'react-bootstrap';
import SpaceCard from "../../../../Components/SpaceCard/SpaceCard";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { relative } from "path";
import { ICreateV2BookingRequest, ICreateV2Booking_BookingParty } from "../../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import { CostCodeWithAllocation } from "../../../../Components/CostCodeModal";
import { RouteComponentProps } from "react-router-dom";
import { ApiError } from "../../../../Providers.Api/ApiError";
import { DateTime } from 'luxon';
import { IRecurringSpaceSeach } from "../../../../Providers.Api/Spaces/GetRecurringEndpoint";

class RecurringBooking extends Component<RouteComponentProps, IState>
{
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private config = appContext().config;
    private apiCache = appContext().apiCache;
    private localStorage = appContext().localStorageProvider;
    private buildingConfig = {} as IBuildingConfig;
    private userPreferences = {} as IUserPreferences;
    private get spaceService() { return appContext().spaceService; }
    private get bookingService() { return appContext().bookingService; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: RouteComponentProps)
    {
        super(props);
        this.state =
        {
            buildingOptions: [],
            selectedBuildingOption: 0,
            floorTypeOptions: [],
            selectedFloor: 0,
            zoneOptions: [],
            selectedZone: 'any',
            workTypeOptions: [],
            selectedWorkType: 'any',
            spaceTypeOptions: [],
            selectedSpaceType: '',
            capacity: '1',
            fromTime: '',
            toTime: '',
            startDate: new Date(),
            recurrencePattern: '',
            endType: '',
            endDate: new Date(),
            numberOfOccurences: 0,
            daysSelected: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            },
            dailyRecurrenceFrequency: '',
            dailyRecurrenceFrequencyValue: 0,
            monthlyRecurrenceFrequencyOption: '',
            monthlyRecurrenceDayValue: 0,
            monthlyRecurrenceMonthValue: 0,
            monthlyRecurrenceFrequencyDropdownValue: '',
            monthlyRecurrenceDayDropdownValue: '',
            monthlyRecurrenceEveryMonthValue: 0,
            avChecked: false,
            cateringChecked: false,
            hearingAidChecked: false,
            presentationAidChecked: false,
            showSearchResultsColumn: true,
            fetchingSpaces: false,
            availableSpaces: [],
            selectedSpace: {
                spaceId: '',
                imageUrl: '',
                spaceName: '',
                spaceType: '',
                capacity: '',
                floor: '',
                zone: '',
                dateRange: '',
                timeRange: '',
                requestedOccurrence: '',
                availableOccurrences: '',
                cateringAvailable: 0,
                presentationAidAvailable: 0,
                hearingAidAvailable: 0,
                requiresAV: 0,
                spaceSetup: 0,
                spaceTypeLabel: '',
                datesFound: []
            },
            showConfirmBookingColumn: false,
            bookingName: '',
            bookingDescription: '',
            onlineMeetingLink: false,
            recurrenceOptions: [],
            showAlternativeSpaceModal: '',
            alternativeSpaceHighlighted: '',
            spaces: [],
            bookingParties: [],
            bookingPartyVisitors: [],
            costCodes: [],
            onBehalfOf: '',
            onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false },
            showCreateProgressModal: false,
            createdBookingsCount: 0,
            createBookingsErrors: [],
            availableAlternativeSpaces: [],
            fetchingAlternativeSpaces: false,
            spacesSearched: false,
            autoCheckin: false
        };
    }
    public async componentDidMount(): Promise<void>
    {
        this.populateBuildings(this.state.selectedBuildingOption);
        this.userPreferences = await this.localStorage.getUserPreferences();
        let rootNode = this.localStorage.getNodeData();
        this.buildingConfig = this.config.getBuildingConfig(rootNode, this.state.selectedBuildingOption);

        if (this.buildingConfig && this.userPreferences.WorkingHoursPrefs)
        {
            let defaultTimes = this.config.getDefaultTimes(this.buildingConfig, this.userPreferences.WorkingHoursPrefs, false);
            this.setState({ fromTime: defaultTimes.start.toJSDate().toISOString(), toTime: defaultTimes.end.toJSDate().toISOString() });

            if (this.userPreferences.Nodes?.length > 0)
            {
                const buildingId = this.userPreferences?.SearchPrefs?.DefaultBuilding;
                const floorId = this.userPreferences?.Nodes?.find(i => i.NodeId === buildingId)?.DefaultFloor;
                if (buildingId != null)
                {
                    this.populateFloors(buildingId);
                    this.populateSpaceTypes(buildingId);
                    this.populateWorkSpaces(buildingId);
                    this.setState({ selectedBuildingOption: buildingId });
                }
                if (floorId != null)
                {
                    this.populateZones(floorId, this.state.selectedZone);
                    this.setState({ selectedFloor: floorId });
                }
            }
        }
    }
    public populateBuildings(selectedValue: number): void
    {
        const buildings: INode[] = getAllBuildingsData();
        const options = buildings.map(i => ({ label: i.Name, value: i.Node_Id }));
        this.setState({ buildingOptions: options.sort((a,b) => a.label > b.label ? 1 : -1), selectedBuildingOption: selectedValue });
    }
    public buildingSelected(selectedBuildingId: number): void
    {
        this.setState({ selectedBuildingOption: selectedBuildingId, selectedFloor: 0, selectedZone: 'any', selectedWorkType: 'any', selectedSpaceType: 'any' })
        this.populateFloors(selectedBuildingId);
        this.populateWorkSpaces(selectedBuildingId);
        this.populateSpaceTypes(selectedBuildingId);
    }
    public populateFloors(selectedBuildingId: number): void
    {
        const floors: IFloor[] = Helper.getFloorsByBuildingId(selectedBuildingId);
        const options = floors
            .map(i => ({ label: i.Node_Name, value: i.Node_Id }))
            .sort((a, b) => (a.label < b.label ? - 1 : 1));
        this.setState({ floorTypeOptions: options, selectedZone: 'any' });
    }
    public floorSelected(id: number): void
    {
        this.setState({ selectedFloor: id })

        if (id == 0)
        {
            this.setState({ zoneOptions: [], selectedZone: 'any' });
        }
        else
        {
            this.populateZones(id, this.state.selectedZone);
        }
    }
    public async populateZones(selectedFloor: number, selectedZone: string): Promise<void>
    {
        const zonesResponse = await this.apiClient.spaceZones.getMultiple(selectedFloor, true);
        const zones = zonesResponse;
        const options = zones.map(i => ({ label: i.Meta_Loc_Zone, value: i.Meta_Loc_Zone }));
        this.setState({ zoneOptions: options, selectedZone: selectedZone });
    }
    public zoneChanged(zoneId: string)
    {
        this.setState({ selectedZone: zoneId });
    }
    public populateWorkSpaces(selectedBuildingId: number): void
    {
        const workSpaces: ISearchConfigItem[] = getWorkSpaceTypesByNodeId(selectedBuildingId);
        const options = workSpaces
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));
        this.setState({ workTypeOptions: options });
    }
    public workTypeChanged(workType: string): void
    {
        this.setState({ selectedWorkType: workType, selectedSpaceType: '' });
    }
    public populateSpaceTypes(selectedBuildingId: number): void
    {
        const spaceTypes: ISearchConfigItem[] = getSpaceTypesByNodeId(selectedBuildingId);

        const options = spaceTypes
            .filter(i => i.Name != null)
            .map(i => ({ label: i.Label, value: i.Name }));
        this.setState({ spaceTypeOptions: options });
    }
    public spaceTypeChanged(spaceType: string): void
    {

        if (spaceType == 'Desk')
        {
            this.setState({ capacity: '1' })
        }
        this.setState({ selectedSpaceType: spaceType, selectedWorkType: 'any' });
    }
    public capacityUpdated(capacity: string): void
    {
        this.setState({ capacity: parseInt(capacity) < 0 ? '0' : capacity })
    }

    public getDates(): IOccurrenceDates[]
    {
        const startFromDateTime = (this.state.startDate as Date).toISOString()?.split('T')[0] + 'T' + new Date(0, 0, 0, DateTime.fromISO(this.state.fromTime).hour, DateTime.fromISO(this.state.fromTime).minute).toISOString()?.split('T')[1];
        const startToDateTime = (this.state.startDate as Date).toISOString()?.split('T')[0] + 'T' + new Date(0, 0, 0, DateTime.fromISO(this.state.toTime).hour, DateTime.fromISO(this.state.toTime).minute).toISOString()?.split('T')[1];

        let dates: IOccurrenceDates[] = [{ start: startFromDateTime, end: startToDateTime }];

        const endDate = new Date(this.state.endDate);
        endDate.setHours(23);
        endDate.setMinutes(59);

        let limitExceeded = false;

        if (this.state.recurrencePattern == 'daily')
        {
            if (this.state.dailyRecurrenceFrequency == 'every')
            {
                while (!limitExceeded)
                {
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ days: this.state.dailyRecurrenceFrequencyValue }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ days: this.state.dailyRecurrenceFrequencyValue }).toString();
                    dates.push({
                        start: new Date(newStartOccurence).toISOString(),
                        end: new Date(newEndOccurence).toISOString(),
                    })
                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                }
            }
            if (this.state.dailyRecurrenceFrequency == 'everyWeekday')
            {
                let increment = 1;
                while (!limitExceeded)
                {
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ days: increment }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ days: increment }).toString();
                    if (DateTime.fromISO(newStartOccurence).weekday !== 6 && DateTime.fromISO(newStartOccurence).weekday !== 7)
                    {
                        dates.push({
                            start: new Date(newStartOccurence).toISOString(),
                            end: new Date(newEndOccurence).toISOString(),
                        })
                        increment = 1;
                    }
                    else
                    {
                        increment += 1;
                    }
                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(DateTime.fromISO(dates[dates.length - 1].start).plus({ days: this.state.dailyRecurrenceFrequencyValue }).toString()) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                }
            }
        }

        if (this.state.recurrencePattern == 'weekly')
        {
            let increment = 1;
            let selectedDaysArray = Object.keys(this.state.daysSelected).filter((item) =>
            {
                if (this.state.daysSelected[item as keyof ISelectedWeekDays]) return item;
            });
            while (!limitExceeded)
            {
                const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ days: increment }).toString();
                const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ days: increment }).toString();

                if (selectedDaysArray.includes(DateTime.fromISO(newStartOccurence).weekdayLong.toLocaleLowerCase()))
                {
                    dates.push({
                        start: new Date(newStartOccurence).toISOString(),
                        end: new Date(newEndOccurence).toISOString(),
                    })
                    increment = 1;
                }
                else
                {
                    increment += 1;
                }
                limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                if (limitExceeded && this.state.endType == 'After')
                {
                    dates.pop()
                }
            }
        }

        if (this.state.recurrencePattern == 'monthly')
        {

            if (this.state.monthlyRecurrenceFrequencyOption == 'multipleDays')
            {
                while (!limitExceeded)
                {
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ month: this.state.monthlyRecurrenceMonthValue }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ month: this.state.monthlyRecurrenceMonthValue }).toString();

                    const totalDaysInMonth = new Date(new Date(newStartOccurence).getFullYear(), new Date(newStartOccurence).getMonth() + 1, 0).getDate()

                    const updatedStartOccurence = new Date(newStartOccurence).setDate(this.state.monthlyRecurrenceDayValue > totalDaysInMonth ? totalDaysInMonth : this.state.monthlyRecurrenceDayValue)
                    const updatedEndOccurence = new Date(newEndOccurence).setDate(this.state.monthlyRecurrenceDayValue > totalDaysInMonth ? totalDaysInMonth : this.state.monthlyRecurrenceDayValue)


                    dates.push({
                        start: new Date(updatedStartOccurence).toISOString(),
                        end: new Date(updatedEndOccurence).toISOString(),
                    })

                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                }
            }
            if (this.state.monthlyRecurrenceFrequencyOption == 'specificDays')
            {
                let datesOfDayInMonth = [];

                while (!limitExceeded)
                {
                    let index = 1;
                    const newStartOccurence = DateTime.fromISO(dates[dates.length - 1].start).plus({ month: this.state.monthlyRecurrenceEveryMonthValue * index }).toString();
                    const newEndOccurence = DateTime.fromISO(dates[dates.length - 1].end).plus({ month: this.state.monthlyRecurrenceEveryMonthValue * index }).toString();

                    const totalDaysInMonth = new Date(new Date(newStartOccurence).getFullYear(), new Date(newStartOccurence).getMonth() + 1, 0).getDate()

                    let dayNum = 1;

                    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

                    while (dayNum < totalDaysInMonth)
                    {
                        if (weekdays[new Date(new Date(newStartOccurence).setDate(dayNum)).getDay()] == this.state.monthlyRecurrenceDayDropdownValue)
                        {
                            datesOfDayInMonth.push({ start: new Date(new Date(newStartOccurence).setDate(dayNum)).toISOString(), end: new Date(new Date(newEndOccurence).setDate(dayNum)).toISOString() })
                        }
                        dayNum += 1;
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'first')
                    {
                        dates.push({
                            start: new Date(datesOfDayInMonth[0].start).toISOString(),
                            end: new Date(datesOfDayInMonth[0].end).toISOString(),
                        })
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'second')
                    {
                        dates.push({
                            start: new Date(datesOfDayInMonth[1].start).toISOString(),
                            end: new Date(datesOfDayInMonth[1].end).toISOString(),
                        })
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'third')
                    {
                        dates.push({
                            start: new Date(datesOfDayInMonth[2].start).toISOString(),
                            end: new Date(datesOfDayInMonth[2].end).toISOString(),
                        })
                    }
                    if (this.state.monthlyRecurrenceFrequencyDropdownValue == 'last')
                    {
                        dates.push({
                            start: new Date(datesOfDayInMonth[datesOfDayInMonth.length - 1].start).toISOString(),
                            end: new Date(datesOfDayInMonth[datesOfDayInMonth.length - 1].end).toISOString(),
                        })
                    }
                    limitExceeded = this.state.endType == 'On' ? endDate <= new Date(newStartOccurence) : dates.length > this.state.numberOfOccurences;
                    if (limitExceeded)
                    {
                        dates.pop()
                    }
                    datesOfDayInMonth = [];
                    index += 1;
                }
            }

        }

        return dates;
    }

    public async searchSpaces(date?: IOccurrenceDates): Promise<void>
    {
        // !date refers to the first columns space search. When a date is passed in it is for alternative spaces.
        if (!date)
        {
            this.setState({
                showSearchResultsColumn: true,
                fetchingSpaces: true,
                showConfirmBookingColumn: false,
                onBehalfOf: '',
                onBehalfOfData: { email: "", firstName: "", lastName: "", company: "", isVisitor: false },
                bookingParties: [],
                bookingPartyVisitors: [],
                costCodes: [],
                selectedSpace: {
                    spaceId: '',
                    imageUrl: '',
                    spaceName: '',
                    spaceType: '',
                    capacity: '',
                    floor: '',
                    zone: '',
                    dateRange: '',
                    timeRange: '',
                    requestedOccurrence: '',
                    availableOccurrences: '',
                    cateringAvailable: 0,
                    presentationAidAvailable: 0,
                    hearingAidAvailable: 0,
                    requiresAV: 0,
                    spaceSetup: 0,
                    spaceTypeLabel: '',
                    datesFound: []
                },
            })
        }

        let dates = []
        if (date)
        {
            dates = [date];
        }
        else
        {
            dates = this.getDates();
        }

        let payload: IRecurringSpaceSeach = {
            Booking_Dates: dates.map(date =>
            ({
                Start_Time: DateTime.fromISO(date.start).setZoneByNode(this.state.selectedBuildingOption),
                End_Time: DateTime.fromISO(date.end).setZoneByNode(this.state.selectedBuildingOption),
            })), 
            Space_Capacity: parseInt(this.state.capacity),
            Space_Type: this.state.selectedSpaceType,
            Meta_Serv_Reqs_AV: this.state.avChecked ? 1 : 0,
            Meta_Serv_Reqs_Catering: this.state.cateringChecked ? 1 : 0,
            Meta_Serv_Reqs_Hearing: this.state.hearingAidChecked ? 1 : 0,
            Meta_Serv_Reqs_Presentation: this.state.presentationAidChecked ? 1 : 0,
            Floor_Id: this.state.selectedFloor,
            Meta_Loc_Zone: this.state.selectedZone
        }

        if(!this.state.avChecked)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_AV')) as IRecurringSpaceSeach;
        }
        if(!this.state.cateringChecked)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_Catering')) as IRecurringSpaceSeach;
        }
        if(!this.state.hearingAidChecked)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_Hearing')) as IRecurringSpaceSeach;
        }
        if(!this.state.presentationAidChecked)
        {
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => key != 'Meta_Serv_Reqs_Presentation')) as IRecurringSpaceSeach;
        }

        const availableSpaces = await this.apiClient.spaces.getRecurringSpaces(this.state.selectedBuildingOption, payload)
        const spaceList = await this.apiCache.getSpacesByBuilding(this.state.selectedBuildingOption);
        this.setState({ spaces: spaceList, spacesSearched: true });
        const spaces = availableSpaces.map(space =>
        {
            const spaceData = spaceList.filter(x => x.Space_Id == space.Space_Id);
            return ({
                spaceId: space.Space_Id,
                imageUrl: spaceData[0]?.ImageURI,
                spaceName: spaceData[0]?.Space_Name,
                spaceType: spaceData[0]?.Space_Type,
                capacity: spaceData[0]?.Space_Capacity.toString(),
                floor: getFloorNameUsingFloorAndBuildingId(this.state.selectedBuildingOption, this.state.selectedFloor),
                zone: spaceData[0]?.Meta_Loc_Zone,
                dateRange: DateTime.fromISO(this.state.startDate.toISOString()).toLocaleString(DateTime.DATE_SHORT),
                timeRange: `${DateTime.fromISO(this.state.fromTime).toLocaleString(DateTime.TIME_24_SIMPLE)} - ${DateTime.fromISO(this.state.toTime).toLocaleString(DateTime.TIME_24_SIMPLE)}`,
                requestedOccurrence: space.Requested.toString(),
                availableOccurrences: space.Found.toString(),
                cateringAvailable: spaceData[0]?.Meta_Serv_Reqs_Catering,
                presentationAidAvailable: spaceData[0]?.Meta_Serv_Reqs_Presentation,
                hearingAidAvailable: spaceData[0]?.Meta_Serv_Reqs_Hearing,
                requiresAV: spaceData[0]?.Meta_Serv_Reqs_AV,
                spaceSetup: spaceData[0]?.Space_Setup,
                spaceTypeLabel: spaceData[0]?.Space_Type_Label,
                datesFound: space.FoundDates
            });
        }).sort((a, b) => a.availableOccurrences < b.availableOccurrences ? 1 : -1);

        if (date)
        {
            this.setState({ availableAlternativeSpaces: spaces, fetchingAlternativeSpaces: false });
        }
        else
        {
            this.setState({ availableSpaces: spaces, fetchingSpaces: false });
        }
    }

    public recurrenceOptionSelected(date: string): void
    {
        const options = this.state.recurrenceOptions;

        const index = options.findIndex(x => x.date == date);
        options[index].selected = !options[index].selected;

        this.setState({ recurrenceOptions: options })

    }

    public spaceSelected(space: IRecurringSpace): void
    {
        const dates = this.getDates();
        this.setState({
            selectedSpace: space,
            showConfirmBookingColumn: true,
            recurrenceOptions: dates.map(date => { return ({ date: date.start.split('T')[0], selected: space.datesFound.includes(date.start.split('T')[0]), alternativeSpace: '' }) })
        });
    }

    public alternativeSpaceSelected(): void
    {
        const spaces = this.state.recurrenceOptions;
        const index = spaces.findIndex(x => x.date == this.state.showAlternativeSpaceModal);
        spaces[index].alternativeSpace = this.state.alternativeSpaceHighlighted;
        spaces[index].selected = true;
        this.setState({ recurrenceOptions: spaces, alternativeSpaceHighlighted: '', showAlternativeSpaceModal: '' });
    }

    private spaceNamesById: (Map<string, string> | null) = null;
    private getSpaceNameById(spaceId: string): string
    {
        this.spaceNamesById = new Map(this.state.spaces.map(i => [i.Space_Id, i.Space_Name]));
        return this.spaceNamesById.get(spaceId) ?? "";
    }


    public fromTimeUpdated(date: string): void
    {
        this.setState({ fromTime: date })
        if (DateTime.fromISO(date) > DateTime.fromISO(this.state.toTime))
        {
            this.setState({ toTime: date });
        }
    }

    public toTimeUpdated(date: string): void
    {
        this.setState({ toTime: date })
        if (DateTime.fromISO(date) < DateTime.fromISO(this.state.fromTime))
        {
            this.setState({ fromTime: date });
        }
    }

    public getPayload(option: IRecurrenceOption): ICreateV2BookingRequest
    {
        const payload: ICreateV2BookingRequest =
        {
            _CreatedAt: "",
            _CreatedBy: "",
            SpaceId: option.alternativeSpace != '' ? option.alternativeSpace : this.state.selectedSpace.spaceId,
            Start: option.date + 'T' + DateTime.fromISO(this.state.fromTime).toJSDate().toISOString().split('T')[1],
            End: option.date + 'T' + DateTime.fromISO(this.state.toTime).toJSDate().toISOString().split('T')[1],
            Name: this.state.bookingName,
            Description: this.state.bookingDescription,
            BookingAutoCheckin: this.state.autoCheckin ? 1 : 0,
            DisableExtUpdate: false,
            Booking_Parties: this.state.bookingParties.concat(this.state.bookingPartyVisitors).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: '',
            })),
            Cost_Code_Allocation: this.state.costCodes.map(costCode =>
            ({
                Cost_Code: costCode.costCode,
                Cost_Code_Id: costCode.costCodeId,
                Allocation: costCode.allocation,
            })),
            AddOnlineMeetingLink: this.state.onlineMeetingLink ? 1 : 0
        };

        if (this.state.onBehalfOf)
        {
            payload.OnBehalfOf = this.state.onBehalfOf;
            if (this.state.onBehalfOfData.isVisitor)
            {
                payload.Booking_Parties.push(
                    {
                        Booking_Participant_Email: this.state.onBehalfOfData.email,
                        Booking_Participant_Name: [this.state.onBehalfOfData.firstName, this.state.onBehalfOfData.lastName].join(" "),
                        Booking_Participant_Organisation: this.state.onBehalfOfData.company,
                        Booking_Participant_Type: 2,
                        Booking_Visitor: this.state.onBehalfOfData.isVisitor,
                        Booking_Resource_Id: '',
                    });
            }
        }

        return payload;
    }

    public createRemainingBookings(): void
    {
        const { history } = this.props;
        this.setState({ showCreateProgressModal: true });
        let index = this.state.createdBookingsCount + 1;
        const selectedOptions = this.state.recurrenceOptions.filter(x => x.selected == true);

        while (index < selectedOptions.length)
        {
            const payload = this.getPayload(selectedOptions[index]);
            this.bookingService.create(this.state.selectedFloor, payload, true);
            index += 1;
        }

        history.push("/flex-my-bookings");
    }

    public async createBookings(): Promise<void>
    {
        const { history } = this.props;
        this.setState({ showCreateProgressModal: true });
        let index = 0;
        const selectedOptions = this.state.recurrenceOptions.filter(x => x.selected == true);

        while (index < selectedOptions.length)
        {
            const payload = this.getPayload(selectedOptions[index]);

            try
            {
                await this.bookingService.create(this.state.selectedFloor, payload, true);
            }
            catch (error)
            {
                const apiError = error as ApiError;
                const errorMessage = (index + 1) + ' / ' + selectedOptions.length + ' - ' + this.state.startDate + ' - ' + apiError.statusText
                this.setState({ createBookingsErrors: [...this.state.createBookingsErrors, errorMessage] });
            }

            index += 1;
            this.setState({ createdBookingsCount: index });
        }

        if (this.state.createBookingsErrors.length == 0)
        {
            history.push("/flex-my-bookings");
        }

    }

    public closeCreatingBookingsModal(): void
    {
        const { history } = this.props;

        if ((this.state.createdBookingsCount + 1 >= this.state.recurrenceOptions.filter(x => x.selected == true).length))
        {
            history.push("/flex-my-bookings");
        }
        else
        {
            this.createRemainingBookings()
        }
    }

    public async showAlternativeSpaces(date: string): Promise<void>
    {
        const dateString = DateTime.fromISO(date).toFormat("yyyy-MM-dd"); // this specific format is required by the api

        this.setState({ showAlternativeSpaceModal: date, fetchingAlternativeSpaces: true });
        await this.searchSpaces({ start: dateString + 'T' + DateTime.fromISO(this.state.fromTime).toJSDate().toISOString().split('T')[1], end: dateString + 'T' + DateTime.fromISO(this.state.toTime).toJSDate().toISOString().split('T')[1] });
    }

    public render(): JSX.Element
    {
        const { fromTime, toTime, endType, endDate, startDate, recurrencePattern, numberOfOccurences, dailyRecurrenceFrequency, dailyRecurrenceFrequencyValue } = this.state;
        const { history } = this.props;

        const disableSearch = (
            this.state.selectedBuildingOption == 0 ||
            this.state.selectedFloor == 0 ||
            (endType == 'On' && startDate > endDate) ||
            endType == '' ||
            this.state.selectedSpaceType == '' ||
            recurrencePattern == '' ||
            (recurrencePattern == 'daily' && dailyRecurrenceFrequency == '') ||
            (recurrencePattern == 'weekly' && Object.values(this.state.daysSelected).indexOf(true) == -1) ||
            (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == '') ||
            (recurrencePattern == 'monthly' && this.state.monthlyRecurrenceFrequencyOption == 'specificDays' && (this.state.monthlyRecurrenceFrequencyDropdownValue == '' || this.state.monthlyRecurrenceDayDropdownValue == ''))
        );

        const alternativeSpaces = this.state.availableAlternativeSpaces.filter(x => x.spaceId != this.state.selectedSpace.spaceId)
        return (
            <main>
                <div className="m-3 ml-5">
                    <div className="pageTitle">{this.labels.HubLabelRecurringBookings}</div>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <div style={{ display: 'flex', width: '100%', overflowX: 'auto' }}>
                            <Grid xs={4} minWidth={387}>
                                <div id="column1" aria-label="search criteria column" className="m-3">
                                    <Card style={{ height: 'calc(100vh - 190px)', overflow: 'auto', borderRadius: '8px' }}>
                                        {/* Column 1 - Criteria */}
                                        <div className="m-4">
                                            <div className='columnHeading mb-3'>{this.labels.HubLabelCriteria}</div>
                                            <div className='columnSubheading mb-3'>{this.labels.HubLabelCriteriaSubHeading}</div>
                                            <IbssFormControl className='mb-3' fullWidth>
                                                <IbssInputDropDown
                                                    value={this.state.selectedBuildingOption}
                                                    inputLabel={this.labels.HubLabelBuilding}
                                                    id='buildingSelect'
                                                    options={this.state.buildingOptions}
                                                    onChange={(e: { target: { value: number; }; }) => this.buildingSelected(e.target.value)}
                                                />
                                            </IbssFormControl>
                                            <IbssFormControl className='mb-3' fullWidth>
                                                <IbssInputDropDown
                                                    value={this.state.selectedFloor}
                                                    disabled={this.state.selectedBuildingOption == 0}
                                                    inputLabel={this.labels.HubLabelFloor}
                                                    id='floorSelect'
                                                    options={[{ label: this.labels.HubLabelAny, value: 0 }, ...this.state.floorTypeOptions]}
                                                    onChange={(e: { target: { value: number; }; }) => this.floorSelected(e.target.value)}
                                                />
                                            </IbssFormControl>
                                            <IbssFormControl className='mb-3' fullWidth>
                                                <IbssInputDropDown
                                                    value={this.state.selectedZone}
                                                    disabled={this.state.selectedFloor == 0}
                                                    inputLabel={this.labels.HubLabelZone}
                                                    id='zoneSelect'
                                                    options={[{ label: this.labels.HubLabelAny, value: 'any' }, ...this.state.zoneOptions]}
                                                    onChange={(e: { target: { value: string; }; }) => this.zoneChanged(e.target.value)}
                                                />
                                            </IbssFormControl>
                                            {/*Disabled until deciding whether field is needed*/}
                                            {/* <IbssFormControl className='mb-3' fullWidth>
                                                <IbssInputDropDown
                                                    value={this.state.selectedWorkType}
                                                    inputLabel={this.labels.HubLabelworkType}
                                                    id='workTypeSelect'
                                                    options={[{ label: 'Any', value: 'any' }, ...this.state.workTypeOptions]}
                                                    onChange={(e: { target: { value: string; }; }) => this.workTypeChanged(e.target.value)}
                                                />
                                            </IbssFormControl> */}
                                            <IbssFormControl className='mb-3' fullWidth>
                                                <IbssInputDropDown
                                                    value={this.state.selectedSpaceType}
                                                    inputLabel={this.labels.HubLabelSpaceType}
                                                    id='spaceTypeSelect'
                                                    options={this.state.spaceTypeOptions}
                                                    onChange={(e: { target: { value: string; }; }) => this.spaceTypeChanged(e.target.value)}
                                                />
                                            </IbssFormControl>
                                            <IbssFormControl className='mb-3' fullWidth>
                                                <IbssTextField
                                                    value={this.state.capacity}
                                                    className='mb-3'
                                                    fullWidth
                                                    label={this.labels.HubLabelCapacity}
                                                    id='capacityTextfield'
                                                    type="number"
                                                    onChange={e => this.capacityUpdated(e.target.value)}
                                                />
                                            </IbssFormControl>
                                            <Box component="div" sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-around' }}>
                                                <Box style={{ alignSelf: 'center' }}>
                                                    {this.labels.HubLabelFrom}
                                                    <div style={{ fontSize: '20px' }}>
                                                        <IbssTimePicker
                                                            className='ibss-timepicker'
                                                            value={DateTime.fromISO(fromTime).toJSDate()}
                                                            onChange={e => e !== null ? this.fromTimeUpdated(DateTime.fromISO(e.toISOString()).toISO()) : {}}
                                                            ampm={false}
                                                            minutesStep={1}
                                                            renderInput={(params) =>
                                                            {
                                                                const { sx, ...paramsMinusSx } = params
                                                                let paramsMinusSxUpdated = {...paramsMinusSx, inputProps: {...paramsMinusSx.inputProps, onChange: () => {}}}
                                                                return <TextField error={false} fullWidth {...paramsMinusSxUpdated} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                />
                                                            }}
                                                        />
                                                    </div>
                                                </Box>
                                                <div
                                                    className="icon-text-inline mt-3"
                                                    style={{
                                                        padding: "10px 15px",
                                                    }}>
                                                    <img src={`/images/Sidebar_Icons/${this.appState.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                                </div>
                                                <div style={{ alignSelf: 'center' }}>
                                                    <div>
                                                        {this.labels.HubLabelTo}
                                                    </div>
                                                    <div style={{ fontSize: '23px' }}>
                                                        <IbssTimePicker
                                                            className='ibss-timepicker'
                                                            value={DateTime.fromISO(toTime).toJSDate()}
                                                            onChange={e => e !== null ? this.toTimeUpdated(DateTime.fromISO(e.toISOString()).toISO()) : {}}
                                                            ampm={false}
                                                            minutesStep={1}
                                                            renderInput={(params) =>
                                                            {
                                                                const { sx, ...paramsMinusSx } = params
                                                                let paramsMinusSxUpdated = {...paramsMinusSx, inputProps: {...paramsMinusSx.inputProps, onChange: () => {}}}
                                                                return <TextField error={false} fullWidth {...paramsMinusSxUpdated} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                />
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Box>
                                            <div className="flexMySearch-filter-criteria-border" style={{ marginTop: 0 }} />
                                            <p className='subText mb-0'>{this.labels.HubLabelStartDateVisitFilter}</p>
                                            <IbssFormControl fullWidth className='mb-3'>
                                                <IbssDatePicker
                                                    disablePast
                                                    value={startDate}
                                                    onChange={(e) => e !== null ? this.setState({ startDate: new Date(e) }) : {}}
                                                    renderInput={(props) =>
                                                    {
                                                        const { sx, ...paramsMinusSx } = props
                                                        let paramsMinusSxUpdated = {...paramsMinusSx, inputProps: {...paramsMinusSx.inputProps, onChange: () => {}}}
                                                        return <TextField {...paramsMinusSxUpdated} size={'medium'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    }}
                                                />
                                            </IbssFormControl>
                                            <IbssFormControl className='mb-3' fullWidth>
                                                <IbssInputDropDown
                                                    value={recurrencePattern}
                                                    inputLabel={this.labels.HubLabelRecurrencePattern}
                                                    id='recurrencePatternSelect'
                                                    options={[{ label: this.labels.HubLabelDaily, value: 'daily' }, { label: this.labels.HubLabelWeekly, value: 'weekly' }, { label: this.labels.HubLabelMonthly, value: 'monthly' },]}
                                                    onChange={(e: { target: { value: string; }; }) => this.setState({ recurrencePattern: e.target.value })}
                                                />
                                            </IbssFormControl>
                                            {
                                                recurrencePattern == 'daily' &&
                                                <div>
                                                    <p className='subText'>{this.labels.HubLabelRepeatEvery}</p>
                                                    <div className='ml-3 behaviours-radioBtns d-flex'>
                                                        <div style={{ paddingTop: '8px' }}>
                                                            <IbssRadioButton
                                                                value={dailyRecurrenceFrequency}
                                                                horizontal
                                                                valueChanged={(value: string) => this.setState({ dailyRecurrenceFrequency: value })}
                                                                option={[{ label: this.labels.HubLabelEvery, value: 'every', labelplacement: 'end' }]}
                                                            />
                                                        </div>
                                                        <div>
                                                            <IbssFormControl className='mb-3'>
                                                                <IbssTextField
                                                                    disabled={dailyRecurrenceFrequency !== 'every'}
                                                                    value={dailyRecurrenceFrequencyValue}
                                                                    type='number'
                                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '75px' }}
                                                                    onChange={e => this.setState({ dailyRecurrenceFrequencyValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 364 ? 364 : parseInt(e.target.value) })}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <div style={{ margin: '16px 0px 0px 15px' }}>
                                                            {this.labels.HubLabelRecurringSpaceDays}
                                                        </div>
                                                    </div>
                                                    <div className='ml-3 behaviours-radioBtns d-flex'>
                                                        <div style={{ paddingTop: '8px' }}>
                                                            <IbssRadioButton
                                                                value={dailyRecurrenceFrequency}
                                                                horizontal
                                                                valueChanged={(value: string) => this.setState({ dailyRecurrenceFrequency: value })}
                                                                option={[{ label: this.labels.HubLabelEveryWeekday, value: 'everyWeekday', labelplacement: 'end' }]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                recurrencePattern == 'weekly' &&
                                                <div className="ml-2 mr-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <IbssCheckBox label={this.labels.HubLabelMonday} id={this.labels.HubLabelMonday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, monday: e.target.checked } })} />
                                                    <IbssCheckBox label={this.labels.HubLabelTuesday} id={this.labels.HubLabelTuesday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, tuesday: e.target.checked } })} />
                                                    <IbssCheckBox label={this.labels.HubLabelWednesday} id={this.labels.HubLabelWednesday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, wednesday: e.target.checked } })} />
                                                    <IbssCheckBox label={this.labels.HubLabelThursday} id={this.labels.HubLabelThursday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, thursday: e.target.checked } })} />
                                                    <IbssCheckBox label={this.labels.HubLabelFriday} id={this.labels.HubLabelFriday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, friday: e.target.checked } })} />
                                                    <IbssCheckBox label={this.labels.HubLabelSaturday} id={this.labels.HubLabelSaturday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, saturday: e.target.checked } })} />
                                                    <IbssCheckBox label={this.labels.HubLabelSunday} id={this.labels.HubLabelSunday} onClicked={(e: { target: { checked: boolean; }; }) => this.setState({ daysSelected: { ...this.state.daysSelected, sunday: e.target.checked } })} />
                                                </div>
                                            }
                                            {
                                                recurrencePattern == 'monthly' &&
                                                <div>
                                                    <div className='ml-3 behaviours-radioBtns d-flex' style={{ flexWrap: 'wrap' }}>
                                                        <div style={{ paddingTop: '8px' }}>
                                                            <IbssRadioButton
                                                                value={this.state.monthlyRecurrenceFrequencyOption}
                                                                horizontal
                                                                valueChanged={(value: string) => this.setState({ monthlyRecurrenceFrequencyOption: value })}
                                                                option={[{ label: this.labels.HublabelDay, value: 'multipleDays', labelplacement: 'end' }]}
                                                            />
                                                        </div>
                                                        <div>
                                                            <IbssFormControl className='mb-3'>
                                                                <IbssTextField
                                                                    disabled={this.state.monthlyRecurrenceFrequencyOption !== 'multipleDays'}
                                                                    value={this.state.monthlyRecurrenceDayValue}
                                                                    type='number'
                                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '63px' }}
                                                                    onChange={e => this.setState({ monthlyRecurrenceDayValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 31 ? 31 : parseInt(e.target.value) })}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <div style={{ margin: '16px 15px 0px 15px' }}>
                                                            {this.labels.HubLabelOfEvery}
                                                        </div>
                                                        <div>
                                                            <IbssFormControl className='mb-3'>
                                                                <IbssTextField
                                                                    disabled={this.state.monthlyRecurrenceFrequencyOption !== 'multipleDays'}
                                                                    value={this.state.monthlyRecurrenceMonthValue}
                                                                    type='number'
                                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '63px' }}
                                                                    onChange={e => this.setState({ monthlyRecurrenceMonthValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 52 ? 52 : parseInt(e.target.value) })}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <div style={{ margin: '16px 0px 0px 15px' }}>
                                                            {this.labels.HubLabelMonths}
                                                        </div>
                                                    </div>
                                                    <div className='ml-3 behaviours-radioBtns d-flex' style={{ flexWrap: 'wrap' }}>
                                                        <div style={{ paddingTop: '8px' }}>
                                                            <IbssRadioButton
                                                                value={this.state.monthlyRecurrenceFrequencyOption}
                                                                horizontal
                                                                valueChanged={(value: string) => this.setState({ monthlyRecurrenceFrequencyOption: value })}
                                                                option={[{ label: this.labels.HubLabelThe, value: 'specificDays', labelplacement: 'end' }]}
                                                            />
                                                        </div>
                                                        <div>
                                                            <IbssFormControl className='mb-3 mr-1' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                                <IbssInputDropDown

                                                                    minWidth={100}
                                                                    disabled={this.state.monthlyRecurrenceFrequencyOption !== 'specificDays'}
                                                                    value={this.state.monthlyRecurrenceFrequencyDropdownValue}
                                                                    id="dayFrequencyDropdown"
                                                                    options={[
                                                                        { label: this.labels.HubLabelFirst, value: 'first' },
                                                                        { label: this.labels.HubLabelSecond, value: 'second' },
                                                                        { label: this.labels.HubLabelThird, value: 'third' },
                                                                        { label: this.labels.HubLabelLast, value: 'last' },
                                                                    ]}
                                                                    onChange={(e: { target: { value: string; }; }) => this.setState({ monthlyRecurrenceFrequencyDropdownValue: e.target.value })}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <div>
                                                            <IbssFormControl className='mb-3' sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                                <IbssInputDropDown
                                                                    minWidth={100}
                                                                    disabled={this.state.monthlyRecurrenceFrequencyOption !== 'specificDays'}
                                                                    value={this.state.monthlyRecurrenceDayDropdownValue}
                                                                    id="weekdayDropdown"
                                                                    options={[
                                                                        { label: this.labels.HubLabelMonday, value: 'monday' },
                                                                        { label: this.labels.HubLabelTuesday, value: 'tuesday' },
                                                                        { label: this.labels.HubLabelWednesday, value: 'wednesday' },
                                                                        { label: this.labels.HubLabelThursday, value: 'thursday' },
                                                                        { label: this.labels.HubLabelFriday, value: 'friday' },
                                                                        { label: this.labels.HubLabelSaturday, value: 'saturday' },
                                                                        { label: this.labels.HubLabelSunday, value: 'sunday' },
                                                                    ]}
                                                                    onChange={(e: { target: { value: string; }; }) => this.setState({ monthlyRecurrenceDayDropdownValue: e.target.value })}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <div style={{ margin: '16px 0px 0px 15px' }}>
                                                            {this.labels.HubLabelOf}
                                                        </div>
                                                        <div style={{ margin: '16px 15px 0px 5px' }}>
                                                            {this.labels.HubLabelEvery.toLocaleLowerCase()}
                                                        </div>
                                                        <div>
                                                            <IbssFormControl className='mb-3'>
                                                                <IbssTextField
                                                                    disabled={this.state.monthlyRecurrenceFrequencyOption !== 'specificDays'}
                                                                    value={this.state.monthlyRecurrenceEveryMonthValue}
                                                                    type='number'
                                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '63px' }}
                                                                    onChange={e => this.setState({ monthlyRecurrenceEveryMonthValue: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 52 ? 52 : parseInt(e.target.value) })}
                                                                />
                                                            </IbssFormControl>
                                                        </div>
                                                        <div style={{ margin: '16px 0px 0px 15px' }}>
                                                            {this.labels.HubLabelMonths}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <p className='subText'>{this.labels.HubLabelEnds}</p>
                                            <div className='ml-3 behaviours-radioBtns d-flex'>
                                                <div style={{ paddingTop: '8px', width: '30%' }}>
                                                    <IbssRadioButton
                                                        value={endType}
                                                        horizontal
                                                        valueChanged={(value: string) => this.setState({ endType: value })}
                                                        option={[{ label: this.labels.HubLabelOn, value: 'On', labelplacement: 'end' }]}
                                                    />
                                                </div>
                                                <div style={{ width: '60%' }}>
                                                    <IbssFormControl fullWidth className='mb-3'>
                                                        <IbssDatePicker
                                                            disabled={endType != 'On'}
                                                            disablePast
                                                            value={endDate}
                                                            onChange={(e) => e !== null ? this.setState({ endDate: e }) : {}}
                                                            renderInput={(props) => <TextField {...props} size={'medium'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                                                        />
                                                    </IbssFormControl>
                                                </div>
                                            </div>
                                            <div className='ml-3 behaviours-radioBtns d-flex'>
                                                <div style={{ paddingTop: '8px', width: '30%' }}>
                                                    <IbssRadioButton
                                                        value={endType}
                                                        horizontal
                                                        valueChanged={(value: string) => this.setState({ endType: value })}
                                                        option={[{ label: this.labels.HubLabelAfter, value: 'After', labelplacement: 'end' }]}
                                                    />
                                                </div>
                                                <div style={{ width: '60%' }}>
                                                    <IbssFormControl fullWidth className='mb-3'>
                                                        <IbssTextField
                                                            disabled={recurrencePattern == '' || endType != 'After'}
                                                            value={numberOfOccurences}
                                                            fullWidth
                                                            variant='standard'
                                                            type='number'
                                                            onChange={e => this.setState({ numberOfOccurences: parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) > 52 ? 52 : parseInt(e.target.value) })}
                                                            label={this.labels.HubLabelOccurrences}
                                                        />
                                                    </IbssFormControl>
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelEquipmentId}/{this.labels.HubLabelFeatures}</p>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <IbssFormControl className='mr-4'><IbssSwitchLabel label={this.labels.HubLabeltvAv} defaultChecked={false} onChange={e => this.setState({ avChecked: e.target.checked })} /></IbssFormControl>
                                                <IbssFormControl className='mr-4'><IbssSwitchLabel label={this.labels.HubMenuCatering} defaultChecked={false} onChange={e => this.setState({ cateringChecked: e.target.checked })} /></IbssFormControl>
                                                <IbssFormControl className='mr-4'><IbssSwitchLabel label={this.labels.HubLabelHearingAid} defaultChecked={false} onChange={e => this.setState({ hearingAidChecked: e.target.checked })} /></IbssFormControl>
                                                <IbssFormControl className='mr-4'><IbssSwitchLabel label={this.labels.HubLabelPresentationAid} defaultChecked={false} onChange={e => this.setState({ presentationAidChecked: e.target.checked })} /></IbssFormControl>
                                            </div>
                                            <IbssButton
                                                disabled={disableSearch}
                                                className="mt-4"
                                                variant="contained"
                                                fullWidth
                                                onClick={() => this.searchSpaces()}>
                                                {this.labels.HubLabelSearch}
                                            </IbssButton>
                                        </div>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid xs={4} minWidth={387}>
                                <div id="column2" aria-label="search results column" className="m-3">
                                    {
                                        this.state.showSearchResultsColumn ?
                                            <div id="column2" aria-label="search results column" className="m-3">
                                                <Card style={{ height: 'calc(100vh - 190px)', overflow: 'auto', borderRadius: '8px' }}>
                                                    {/* Column 2 - Results */}
                                                    {
                                                        this.state.fetchingSpaces ?
                                                            <div className="m-4" style={{ position: 'relative'}}>
                                                                <SpaceSkeleton />
                                                                <SpaceSkeleton />
                                                                <SpaceSkeleton />
                                                            </div>
                                                            :
                                                            <div className="m-4">
                                                                <div className='columnHeading mb-3'>{this.labels.HubLabelSelectASpace}</div>
                                                                <div className='columnSubheading mb-3'>{this.labels.HubLabelSelectASpaceSubheading}</div>
                                                                {
                                                                    this.state.availableSpaces?.length > 0 ?
                                                                        this.state.availableSpaces.map((space, index) =>
                                                                        {
                                                                            return (
                                                                                <div onClick={() => this.spaceSelected(space)} onKeyDown={(e) => e.keyCode == 32 ? this.spaceSelected(space) : {}} tabIndex={0} role="presentation" aria-label="space option">
                                                                                    <RecurringSpaceCard {...space} selected={this.state.selectedSpace.spaceId == space.spaceId} bestFit={index == 0} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                                                                            <div className="noResultsHeading">{this.state.spacesSearched ? this.labels.HubLabelNoSpaceOptions : this.labels.HubLabelEnterYourCriteria}</div>
                                                                            <div className="noResultsSubText mt-1">{this.state.spacesSearched ? this.labels.HubLabelNoSpaceOptionsSubText : this.labels.HubLabelPickYourCriteria}</div>
                                                                        </div>
                                                                }
                                                            </div>
                                                    }
                                                </Card>
                                            </div>
                                            :
                                            <div style={{ border: '1px dashed darkgrey', borderRadius: '8px', height: 'calc(100vh - 190px)', textAlign: 'center' }}>
                                                <div style={{ position: 'relative', top: '42%', color: 'darkgrey', fontSize: '80px' }}>2</div>
                                            </div>
                                    }
                                </div>
                            </Grid>
                            <Grid xs={4} minWidth={387}>
                                <div id="column3" aria-label="confirm booking column" className="m-3">
                                    {/* Column 3 - Confirm booking */}
                                    {
                                        this.state.showConfirmBookingColumn ?
                                            <Card style={{ height: 'auto', maxHeight: 'calc(100vh - 190px)', overflow: 'auto', borderRadius: '8px' }}>
                                                <div className="m-4">
                                                    <div className='columnHeading mb-3'>{this.labels.HubLabelBookingDetails}</div>
                                                    <div className='columnSubheading mb-3'>{this.labels.HubLabelAddBookingDetail}</div>

                                                    <IbssFormControl fullWidth className='mb-3'>
                                                        <IbssTextField
                                                            value={this.state.bookingName}
                                                            fullWidth
                                                            variant='standard'
                                                            onChange={e => this.setState({ bookingName: e.target.value })}
                                                            label={this.labels.HubLabelBookingName}
                                                        />
                                                    </IbssFormControl>
                                                    <IbssFormControl fullWidth className='mb-3'>
                                                        <IbssTextField
                                                            value={this.state.bookingDescription}
                                                            fullWidth
                                                            variant='standard'
                                                            onChange={e => this.setState({ bookingDescription: e.target.value })}
                                                            label={this.labels.HubLabelBookingDescription}
                                                        />
                                                    </IbssFormControl>
                                                    <IbssFormControl className='mr-4 mb-3'>
                                                        <IbssSwitchLabel label={this.labels.HubLabelOnlineMeetingLink} defaultChecked={false} onChange={e => this.setState({ onlineMeetingLink: e.target.checked })} />
                                                    </IbssFormControl>
                                                    <hr />
                                                    <BookForSomeoneElse onSubmit={(onBehalfOf: string, onBehalfOfData: IOnBehalfOf, autoCheckin: boolean) => this.setState({ onBehalfOf: onBehalfOf, onBehalfOfData: onBehalfOfData, autoCheckin: autoCheckin })} />
                                                    {
                                                        parseInt(this.state.selectedSpace.capacity) > 1 &&
                                                        <AddAttendees onSubmit={(parties: IAttendee[], visitors: IAttendee[]) => this.setState({ bookingParties: parties, bookingPartyVisitors: visitors })} />
                                                    }
                                                    {
                                                        this.state.selectedSpace.cateringAvailable == 1 &&
                                                        <AddCostCodes onSubmit={(costCodes: CostCodeWithAllocation[]) => this.setState({ costCodes: costCodes })} />
                                                    }
                                                    {
                                                        this.state.selectedSpace.hearingAidAvailable == 1 &&
                                                        <div>
                                                            <div className="row">
                                                                <div className="disabled-labels" style={{ justifyContent: 'unset', marginTop: '-1rem' }}>
                                                                    <IbssSvgIcon sx={{ marginTop: '3px' }} fontSize='medium' className="mr-2">
                                                                        {Icons.HearingAidIcon}
                                                                    </IbssSvgIcon>
                                                                    <div className="icon-text-inline">
                                                                        <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelHearingConfirmation}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-0" />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.selectedSpace.presentationAidAvailable == 1 &&
                                                        <div>
                                                            <div className="row">
                                                                <div className="disabled-labels" style={{ justifyContent: 'unset', marginTop: '-1rem' }}>
                                                                    <IbssSvgIcon sx={{ marginTop: '3px' }} fontSize='medium' className="mr-2">
                                                                        {Icons.PresentationAidIcon}
                                                                    </IbssSvgIcon>
                                                                    <div className="icon-text-inline">
                                                                        <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelconfirmation}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-0" />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.selectedSpace.cateringAvailable == 1 &&
                                                        <div>
                                                            <div className="row">
                                                                <div className="disabled-labels" style={{ justifyContent: 'unset', marginTop: '-1rem' }}>
                                                                    <IbssSvgIcon sx={{ marginTop: '3px' }} fontSize='medium' className="mr-2">
                                                                        {Icons.CateringIcon}
                                                                    </IbssSvgIcon>
                                                                    <div className="icon-text-inline">
                                                                        <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelCatering}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="m-0" />
                                                        </div>
                                                    }
                                                    <h3 style={{ color: '#7A7B82' }}>{`${this.labels.HubLabelRecurrenceDates} (${this.state.recurrenceOptions.filter(x => x.selected).length})`}</h3>
                                                    {
                                                        this.state.recurrenceOptions.map((option) =>
                                                        {
                                                            return (
                                                                <div className="d-flex" style={{ marginBottom: '5px' }}>
                                                                    <div style={{ width: '50%', alignSelf: 'center' }}>
                                                                        <IbssCheckBox disabled={!this.state.selectedSpace.datesFound.includes(option.date) && option.alternativeSpace == ''} checked={option.selected} label={option.date} id={option.date} onClicked={(e: { target: { checked: boolean; }; }) => this.recurrenceOptionSelected(option.date)} />
                                                                    </div>
                                                                    {
                                                                        option.selected ?
                                                                            <div style={{ width: '50%' }}>
                                                                                <div style={{ float: 'right', marginRight: '5px', marginTop: '9px' }}>
                                                                                    <div style={{ textAlign: 'center' }}>
                                                                                        <div>
                                                                                            {DateTime.fromISO(this.state.fromTime).toLocaleString(DateTime.TIME_24_SIMPLE)} - {DateTime.fromISO(this.state.toTime).toLocaleString(DateTime.TIME_24_SIMPLE)}
                                                                                        </div>
                                                                                        {
                                                                                            option.alternativeSpace.length > 0 &&
                                                                                            <div className="alternativeSpace">
                                                                                                {this.getSpaceNameById(option.alternativeSpace)}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div style={{ width: '50%' }}>
                                                                                <div style={{ float: 'right', marginRight: '5px' }}>
                                                                                    <IbssButtonRedo
                                                                                        className="btn-back ml-3 col-text"
                                                                                        variant='contained'
                                                                                        color="secondary"
                                                                                        onClick={() => this.showAlternativeSpaces(option.date)}
                                                                                    >
                                                                                        {this.labels.HubLabelAlternative}
                                                                                    </IbssButtonRedo>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <IbssButton
                                                        disabled={this.state.recurrenceOptions.filter(x => x.selected == true).length == 0 || this.state.bookingName == ''}
                                                        className="mt-4"
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={() => this.createBookings()}>
                                                        {`${this.labels.HubButtonConfirmBooking} (${this.state.recurrenceOptions.filter(x => x.selected == true).length}/${this.state.recurrenceOptions.length})`}
                                                    </IbssButton>
                                                </div>
                                            </Card>
                                            :
                                            <div style={{ border: '1px dashed darkgrey', borderRadius: '8px', height: 'calc(100vh - 190px)', textAlign: 'center' }}>
                                                <div style={{ position: 'relative', top: '42%', color: 'darkgrey', fontSize: '80px' }}>3</div>
                                            </div>
                                    }
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                    <Modal show={this.state.showAlternativeSpaceModal != ''} onHide={() => this.setState({ showAlternativeSpaceModal: '' })}>
                        <div aria-label="alternative space modal">
                            <Modal.Header>
                                <Modal.Title>{this.labels.HubLabelPickAlternativeSpace}</Modal.Title>
                                <button type="button" className="close" onClick={() => this.setState({ showAlternativeSpaceModal: '' })} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </Modal.Header>
                            <div style={{ paddingTop: '5px', paddingLeft: '38px', paddingRight: '38px' }} className="mb-4">
                                {this.labels.HubLabelAlternativeSpaceText}
                                <br />
                                <br />
                                {this.labels.HubLabelDate}: {DateTime.fromISO(this.state.startDate.toISOString()).toLocaleString(DateTime.DATE_SHORT)} | {this.labels.HubLabelTime}: {DateTime.fromISO(this.state.fromTime).toLocaleString(DateTime.TIME_24_SIMPLE)} | {this.labels.HubLabelCapacity}: {this.state.capacity} |
                            </div>
                            <div>
                                {
                                    this.state.fetchingAlternativeSpaces ?
                                        <div style={{ height: '200px' }}>
                                            <Spinner />
                                        </div>
                                        :
                                        <div style={{ display: 'flex', overflow: 'auto', marginLeft: '37px' }}>
                                            {
                                                alternativeSpaces.length == 0 ?
                                                    <div style={{ width: '100%', textAlign: 'center', marginBottom: '10px' }}>
                                                        <img className="mt-2" alt="NoSpacesImage" src="/images/NoSpaceSearchReults.svg" />
                                                        <div className="noResultsHeading">{this.labels.HubLabelNoSpaceOptions}</div>
                                                        <div className="noResultsSubText mt-1">{this.labels.HubLabelNoSpaceOptionsSubText}</div>
                                                    </div>
                                                    :
                                                    alternativeSpaces.map(space =>
                                                    {
                                                        return (
                                                            <div>
                                                                <SpaceCard
                                                                    imageUrl={space.imageUrl}
                                                                    nodeId={this.state.selectedBuildingOption}
                                                                    floorName={space.floor}
                                                                    zone={space.zone}
                                                                    spaceId={space.spaceId}
                                                                    spaceName={space.spaceName}
                                                                    spaceType={space.spaceType}
                                                                    spaceTypeLabel={space.spaceTypeLabel}
                                                                    spaceCapacity={parseInt(space.capacity)}
                                                                    requiresCatering={space.cateringAvailable}
                                                                    requiresAV={space.requiresAV}
                                                                    requiresHearing={space.hearingAidAvailable}
                                                                    requiresPresentation={space.presentationAidAvailable}
                                                                    spaceSetup={space.spaceSetup}
                                                                    showBookingOwner={false}
                                                                    bookingStart={DateTime.fromISO(this.state.startDate.toLocaleString())}
                                                                    bookingEnd={DateTime.fromISO(this.state.endDate.toLocaleString())}
                                                                    bookingOwnerName=""
                                                                    bookingShareLocation={false}
                                                                    showNoBookingMessage={false}
                                                                    onClick={() => this.setState({ alternativeSpaceHighlighted: space.spaceId })}
                                                                    border={true}
                                                                    selected={space.spaceId == this.state.alternativeSpaceHighlighted}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                }
                            </div>
                            <Modal.Footer>
                                <div style={{ textAlign: 'center' }}>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        variant="contained"
                                        disabled={alternativeSpaces.length == 0 ? false : this.state.alternativeSpaceHighlighted == ''}
                                        onClick={() => alternativeSpaces.length == 0 ? this.setState({ showAlternativeSpaceModal: '' }) : this.alternativeSpaceSelected()}
                                    >
                                        {alternativeSpaces.length == 0 ? this.labels.HubLabelSkipOccurrence : this.labels.HubLabelUseSpaceSelected}
                                    </IbssButton>
                                </div>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    <Modal show={this.state.showCreateProgressModal} onHide={() => this.closeCreatingBookingsModal()}>
                        <div aria-label="alternative space modal">
                            <Modal.Header>
                                <Modal.Title>{this.labels.HubLabelCreateBookings}</Modal.Title>
                                <button type="button" className="close" onClick={() => this.closeCreatingBookingsModal()} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </Modal.Header>
                            <div style={{ paddingTop: '15px', paddingLeft: '38px', paddingRight: '38px' }}>
                                <div className="mb-4">
                                    {this.labels.HubLabelCreatingRecurringBookingsText}
                                </div>
                                <div className="mb-4 mt-4" style={{ textAlign: 'center' }}>
                                    {this.state.createdBookingsCount - this.state.createBookingsErrors.length} / {this.state.recurrenceOptions.filter(x => x.selected == true).length} {this.labels.HubLabelCreated}
                                    <LinearProgress sx={{ marginTop: '5px' }} variant="determinate" value={(this.state.createdBookingsCount / this.state.recurrenceOptions.filter(x => x.selected == true).length) * 100} />
                                </div>
                                {
                                    this.state.createBookingsErrors.map(error =>
                                    {
                                        return (
                                            <div className="text-danger mb-1">{error}</div>
                                        )
                                    })
                                }
                            </div>
                            <Modal.Footer>
                                <div style={{ textAlign: 'center' }}>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        variant="contained"
                                        onClick={() => this.closeCreatingBookingsModal()}
                                    >
                                        {this.labels.HubLabelClose}
                                    </IbssButton>
                                </div>
                            </Modal.Footer>
                        </div>
                    </Modal>
                </div>
            </main>
        );
    }
}
export default RecurringBooking;

export class SpaceSkeleton extends Component
{
    public render(): JSX.Element
    {
        return (
            <Card className="mb-1">
            <div>
                <Skeleton variant="rectangular" width={'100%'} height={130} />
                <div style={{ width: '100%' }}>
                    <Skeleton sx={{ margin: '15px', marginBottom: '10px' }} variant="rectangular" width={'70%'} height={10} />
                    <Skeleton sx={{ margin: '15px' }} variant="rectangular" width={'50%'} height={10} />
                </div>
            </div>
            </Card>
        )
    }
}
export interface IState
{
    selectedBuildingOption: number;
    buildingOptions: Array<IListOption<number>>,
    selectedFloor: number,
    floorTypeOptions: Array<IListOption<number>>,
    zoneOptions: Array<IListOption<string>>,
    selectedZone: string,
    workTypeOptions: Array<IListOption<string>>,
    selectedWorkType: string,
    spaceTypeOptions: Array<IListOption<string>>,
    selectedSpaceType: string,
    capacity: string,
    fromTime: string,
    toTime: string,
    startDate: Date,
    recurrencePattern: string,
    endType: string,
    endDate: Date,
    numberOfOccurences: number,
    daysSelected: ISelectedWeekDays,
    dailyRecurrenceFrequency: string,
    dailyRecurrenceFrequencyValue: number,
    monthlyRecurrenceFrequencyOption: string,
    monthlyRecurrenceDayValue: number,
    monthlyRecurrenceMonthValue: number,
    monthlyRecurrenceFrequencyDropdownValue: string,
    monthlyRecurrenceDayDropdownValue: string,
    monthlyRecurrenceEveryMonthValue: number
    avChecked: boolean,
    cateringChecked: boolean,
    hearingAidChecked: boolean,
    presentationAidChecked: boolean,
    showSearchResultsColumn: boolean,
    fetchingSpaces: boolean,
    availableSpaces: IRecurringSpace[],
    selectedSpace: IRecurringSpace,
    showConfirmBookingColumn: boolean,
    bookingName: string,
    bookingDescription: string,
    onlineMeetingLink: boolean,
    recurrenceOptions: IRecurrenceOption[],
    showAlternativeSpaceModal: string,
    alternativeSpaceHighlighted: string;
    spaces: Space[];
    bookingParties: IAttendee[];
    bookingPartyVisitors: IAttendee[];
    costCodes: CostCodeWithAllocation[];
    onBehalfOf: string;
    onBehalfOfData: IOnBehalfOf;
    showCreateProgressModal: boolean;
    createdBookingsCount: number;
    createBookingsErrors: string[];
    availableAlternativeSpaces: IRecurringSpace[];
    fetchingAlternativeSpaces: boolean;
    spacesSearched: boolean;
    autoCheckin: boolean;
}
export interface IListOption<TValue>
{
    label: string,
    value: TValue,
}

export interface ISelectedWeekDays
{
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
}

export interface IOccurrenceDates
{
    start: string,
    end: string
}

export interface IRecurringSpace
{
    spaceId: string;
    imageUrl: string;
    spaceName: string;
    spaceType: string;
    capacity: string;
    floor: string;
    zone: string;
    dateRange: string;
    timeRange: string;
    requestedOccurrence: string;
    availableOccurrences: string;
    cateringAvailable: number;
    presentationAidAvailable: number;
    hearingAidAvailable: number;
    requiresAV: number;
    spaceSetup: number;
    spaceTypeLabel: string;
    datesFound: string[];
}

export interface IRecurrenceOption
{
    date: string;
    selected: boolean;
    alternativeSpace: string;
}
