export default class Labels
{
    public HubLabelWelcomeText = "";
    public HubLabelforgotPassword = "";
    public HubLabelSignInText = "";
    public HubLabelemailPlaceholderText = "";
    public HubLabelPasswordPlaceholderText = "";
    public HubButtonContinue = "";
    public HubButtonSubmit = "";
    public HubMenuportfolioText = "";
    public HubLabelSpaces = "";
    public HubMenuSpaces = "";
    public HubMenuSecurity = "";
    public HubLabelIdentityproviders = "";
    public HubMenuIdentityproviders = "";
    public HubLabelIdentityprovider = "";
    public HubLabelRoles = "";
    public HubMenuRoles = "";
    public HubLabelDevices = "";
    public HubTabTaskTypes = "";
    public HubLabelNotifications = "";
    public HubMenuNotifications = "";
    public HubLabelUsers = "";
    public HubMenuUsers = "";
    public HubLabelName = "";
    public HublabelcreatedAt = "";
    public HubLabelNamePlaceholderText = "";
    public HubLabelIdentityProviderType = "";
    public HubLabelIdentityProviderPlaceholderText = "";
    public HubLabelClientID = "";
    public HubLabelClientIDPlaceholderText = "";
    public HubLabelDirectoryClientID = "";
    public HubLabelTenantDomain = "";
    public HubLabelAdditionalAudiences = "";
    public HubLabelTenantDomainText = "";
    public HubLabelAudiences = "";
    public HubLabelProviderClientIDPlaceholderText = "";
    public HubLabelClientSecret = "";
    public HubLabelClaimType = "";
    public HubLabelClientSecretPLR = "";
    public HubLabelClaimTypePLR = "";
    public HubLabelMinimumPasswordLength = "";
    public HubLabelMinPasswordLength = "";
    public HubLabelMaxPasswordLength = "";
    public HubLabelPasswordComplexity = "";
    public HubLabelMaxPasswordAge = "";
    public HubLabelLoadingText = "";
    public HubLabelStepOne = "";
    public HubLabelStepTwo = "";
    public HubLabelCommonProperties = "";
    public HubTabProperties = "";
    public HubLabelVisitorDetails = "";
    public HubLabelflexFilterSearchCritera = "";
    public HubLabelAssociatedDetails = "";
    public HubLabelStatus = "";
    public HubLabelDisabled = "";
    public HubLabelEnabled = "";
    public HubButtonCancel = "";
    public HubButtonDelete = "";
    public HubButtonDownload = "";
    public HubButtonNext = "";
    public HubButtonPrevious = "";
    public HubButtonSave = "";
    public HubLabelNativeIdentityProvider = "";
    public HubButtonNewUser = "";
    public HubButtonNewRole = "";
    public HubLabelManageRoles = "";
    public HubTabOpservSpacesFree = "";
    public HubLabelIdentityProviderGroupID = "";
    public HubLabelApplicationActions = "";
    public HubLabelIsOwner = "";
    public HublabelIsActive = "";
    public HubLabelEmailAddress = "";
    public HubLabelFirstName = "";
    public HubLabelLastName = "";
    public HubButtonConfirmBooking = "";
    public HubLabelDisplayName = "";
    public HubLabelPhoneNumber = "";
    public HubLabelDeletedAt = "";
    public HubLabelFirstNamePlaceholder = "";
    public HubLabelProvideLastName = "";
    public HubLabelEmailAddressPlaceholder = "";
    public HubLabelDisplayNamePlaceholder = "";
    public HubLabelLocationPlaceholder = "";
    public HubLabelDeletedAtPlaceholder = "";
    public HubLabelPhoneNumberPlaceholder = "";
    public HubTabUserDetails = "";
    public HubTabUserPreferecnces = "";
    public HubLabelActiveRoles = "";
    public HubLabeleventTypeCol = "";
    public HubLabelCreateCol = "";
    public HubLabelReadCol = "";
    public HubLabelUpdateCol = "";
    public HubLabelDeleteCol = "";
    public HubLabelLockCol = "";
    public HubLabelUnlockCol = "";
    public HubLabelShareCol = "";
    public HubMenuOrganisation = "";
    public HubMenuRegions = "";
    public HubLabelBuildings = "";
    public HubMenuBuildings = "";
    public HubMenuFloor = "";
    public HubLabelFloors = "";
    public HubMenuFloorLabel = "";
    public HubMenuZones = "";
    public HubLabelZones = "";
    public HubLabelSensorDetail = "";
    public HubLabelCode = "";
    public HubLabelType = "";
    public HubLabelCapacity = "";
    public HubLabelSize = "";
    public HubLabelActive = "";
    public HubLabelaccessDeniedLabel = "";
    public HubLabelaccessDenied = "";
    public HubLabelUnauthorisedMessage = "";
    public HubLabelSearchable = "";
    public HubButtonAdd = "";
    public HubButtonExport = "";
    public HubButtonEdit = "";
    public HubLabelSpaceConfig = "";
    public HubTabSpaceProperties = "";
    public HubTabSpaceParameters = "";
    public HubTabSpaceParts = "";
    public HubTabSpaceIsPartOfTab = "";
    public HubLabelLabel = "";
    public HubLabelSection = "";
    public HubLabelItemCode = "";
    public HubLabelPreBookable = "";
    public HubLabelCateringTable = "";
    public HubLabelRoomService = "";
    public HubLabelCost = "";
    public HubLabelValue = "";
    public HubLabelSpace = "";
    public HubLabelDeviceType = "";
    public HubLabelDeviceCode = "";
    public HubLabelDeviceMode = "";
    public HubTaskStates = "";
    public HubLabelSpaceStatesl = "";
    public HubLabelTaskTypes = "";
    public HubLabelTaskDetails = "";
    public HubLabelTaskDetailsAdd = "";
    public HubLabelCateringMenu = "";
    public HubLabelTaskCateory = "";
    public HubLabelSpaceType = "";
    public HubLabelBySpaceType = "";
    public HubLabelworkType = "";
    public HubLabelSelectStart = "";
    public HubLabelSelectEnd = "";
    public HubLabelCountry = "";
    public HubLabelCity = "";
    public HubLabelAddress = "";
    public HubLabelLongitude = "";
    public HubLabelLatitude = "";
    public HubLabelLevelNumber = "";
    public HubLabelNewSpaces = "";
    public HubLabelSpaceConfiguration = "";
    public HubLabelstateId = "";
    public HubLabelStateName = "";
    public HubLabelHeaderText = "";
    public HubLabelBodyText = "";
    public HubLabelIcon = "";
    public HubLabelAlternate = "";
    public HubLabelPrimary = "";
    public HubLabelPrimaryCommand = "";
    public HubLabelSecondary = "";
    public HubLabelSecondaryCommand = "";
    public HubLabelTertiaryLabel = "";
    public HubLabelTertiaryCommand = "";
    public HubLabelvalue0Label = "";
    public HubLabelvalue1Label = "";
    public HubLabelShowSpace = "";
    public HubLabelShowNotes = "";
    public HubTabCallToAction = "";
    public HubTabVisualSettings = "";
    public HubLabelPreHeaderText = "";
    public HubLabelLabelText = "";
    public HubLabelColour = "";
    public HubLabelShowFault = "";
    public HubLabelShowControls = "";
    public HubLabelShowInfo = "";
    public HubLabelShowSchedule = "";
    public HubLabelShowBooking = "";
    public HubLabelShowCustom = "";
    public HubLabelValidationField = "";
    public HubLabelHeaderTextPlaceholder = "";
    public HubLabelIconLabelPlaceHolder = "";
    public HubLabelSpaceLabelPlaceholder = "";
    public HubLabelprimaryPlaceholder = "";
    public HubLabelPrimaryCommandPlaceholder = "";
    public HubLabelSecondaryPlaceholder = "";
    public HubLabelSecondaryCommandPlaceholder = "";
    public HubLabelTertiaryPlaceholder = "";
    public HubLabelTertiaryCommandPlaceholder = "";
    public HubLabelValue0Placeholder = "";
    public HubLabelValue1Placeholder = "";
    public HubLabelStateIdPlaceholder = "";
    public HubLabelStateNamePlaceholder = "";
    public HubLabelBodyTextPlaceholder = "";
    public HubLabelPreHeaderTextPlaceholder = "";
    public HubLabelLabelPlaceholderText = "";
    public HubLabelColourPlaceholder = "";
    public HubLabelAlternatePlaceholder = "";
    public HubLabelShowFaultLabelPlaceholder = "";
    public HubLabelShowControlsLabelPlaceholder = "";
    public HubLabelShowInfoLabelPlaceholder = "";
    public HubLabelSchedulePlaceholder = "";
    public HubLabelShowBookingPlaceholder = "";
    public HubLabelShowCustomPlaceholder = "";
    public HubLabelValidationFieldPlaceholder = "";
    public HubLabelRoleLabel = "";
    public HubLabelTaskCategoryNamePlaceholder = "";
    public HubLabelImageURI = "";
    public HubLabelImageURIPlaceholder = "";
    public HubLabelDeviceId = "";
    public HubLabelDeviceIdPlaceholder = "";
    public HubLabelDeviceTypePlaceholder = "";
    public HubLabelDeviceCodePlaceholder = "";
    public HubLabelDeviceModePlaceholder = "";
    public HubLabelSpaceIdPlaceholder = "";
    public HubLabelSignageId = "";
    public HubLabelSignageIdPlaceholder = "";
    public HubLabelOEMMode = "";
    public HubLabelOEMModePlaceholder = "";
    public HubLabelOEMClientImageURI = "";
    public HubLabelOEMClientImageURIPlaceholder = "";
    public HubLabelDeviceOEMBrandImageURI = "";
    public HubLabelOEMBrandImageURIPlaceholder = "";
    public HubLabelGeneralErrorMessage = "";
    public HubLabelGeneralErrorMessagePlaceholder = "";
    public HubLabelAuthenticationError = "";
    public HubLabelAuthenticationErrorPlaceholder = "";
    public HubLabelTypeLabelPlaceholder = "";
    public HubLabelLabelPlaceholder = "";
    public HubLabelSectionPlaceholder = "";
    public HubLabelDescription = "";
    public HubLabelDescriptionPlaceholder = "";
    public HubLabelToggleValues = "";
    public HubLabelToggleValuesPlaceholder = "";
    public HubLabelToggleValueCount = "";
    public HubLabelToggleValueCountPlaceholder = "";
    public HubLabelInitialValue = "";
    public HubLabelInitialValuePlaceholder = "";
    public HubLabelLowLimit = "";
    public HubLabelLowLimitPlaceholder = "";
    public HubLabelHighLimitLabel = "";
    public HubLabelHighLimitPlaceholder = "";
    public HubLabelUnits = "";
    public HubLabelUnitsPlaceholder = "";
    public HubTabBuildingKPIs = "";
    public HubLabelKPIType = "";
    public HubLabelText0 = "";
    public HubLabelText0PlaceHolder = "";
    public HubLabelText1 = "";
    public HubLabelText1PlaceHolder = "";
    public HubLabelText2 = "";
    public HubLabelText2PlaceHolder = "";
    public HubLabelValueURI = "";
    public HubLabelValueURIPlaceHolder = "";
    public HubLabelMaximumValue = "";
    public HubLabelMaximumValuePlaceholder = "";
    public HubLabelLimit1 = "";
    public HubLabellimit1Placeholder = "";
    public HubLabelLimit2 = "";
    public HubLabelLimit2Placeholder = "";
    public HubLabelMode = "";
    public HubLabelOrientation = "";
    public HubLabellimit1PlaceholderText = "";
    public HubLabelSignage = "";
    public HubLabelsignageMode = "";
    public HubLabelsignagePlaceholder = "";
    public HubLabelShowBuildingName = "";
    public HubLabelBuildingNamePlaceholder = "";
    public HubLabelRememberCheckIn = "";
    public HubLabelxLocation = "";
    public HubLabelxLocationPlaceholder = "";
    public HubLabelyLocation = "";
    public HubLabelyLocationPlaceholder = "";
    public HubLabelResetPassword = "";
    public HubMenuRoomsDesks = "";
    public HubTabAwaitingApproval = "";
    public HubTabToday = "";
    public HubTabNextWorkingDay = "";
    public HubTabOnSite = "";
    public HubLabelApproved = "";
    public HubLabelInActive = "";
    public HubLabelFormat = "";
    public HubLabelChecked = "";
    public HubLabelUnchecked = "";
    public HubLabelArrivalLocation = "";
    public HubLabelBookable = "";
    public HubLabelReservationRequired = "";
    public HubLabelMinBookingDurationminutes = "";
    public HubLabelMaxBookingDurationminutes = "";
    public HubLabelSignsofLifeThresholdminutes = "";
    public HubLabelCleanThresholdminutes = "";
    public HubLabelOccupancySystem = "";
    public HubLabelOccupancyCountMode = "";
    public HubLabelareaId = "";
    public HubLabelSensorId = "";
    public HubLabelSensor = "";
    public HubMenuSensor = "";
    public HubTabOpservSpacesOccupied = "";
    public HubLabelextBookingSystemLabel = "";
    public HubLabelBookingSystemSpace = "";
    public HubLabelBookingDetails = "";
    public HubLabelextCateringSystem = "";
    public HubLabelCateringSystemSpace = "";
    public HubLabelGatewayMode = "";
    public HubLabelGateway = "";
    public HubLabelBookingFor = "";
    public HubLabelBookingOrMeetingName = "";
    public HubLabelLightControlMode = "";
    public HubLabelLightScenes = "";
    public HubLabelAVSourceScenes = "";
    public HubLabelSpaceTypeLabel = "";
    public HubLabelOccupancyType = "";
    public HubLabelCapacityMaximumOccupancy = "";
    public HubLabelmapURI = "";
    public HubLabelyposition = "";
    public HubLabelxpositionLabel = "";
    public HubLabelCustomInformation = "";
    public HubTabCustomFilter = "";
    public HubLabelSpaceClass = "";
    public HubLabelworkTypeLabel = "";
    public HubLabelId = "";
    public HubLabelOpservSpacesFiltered = "";
    public HubLabelExternalSystems = "";
    public HubLabelerrorSomethingWentWrong = "";
    public HublabelErrorEmptyData = "";
    public HubLabelConfirmCancelMessage = "";
    public HubLabelNative = "";
    public HubLabelAAD = "";
    public HubLabeloktaLabel = "";
    public HubLabelDeleteMessage = "";
    public HubLabelRoleDetailsSubMenu = "";
    public HubLabelOktaProperties = "";
    public HubLabelAzureADProperties = "";
    public HubLabelNativeProperty = "";
    public HubLabelOKTAIdentity = "";
    public HubLabelZeroNotAcceptable = "";
    public HubLabelNewProvider = "";
    public HubLabelOnecharacterAnyType = "";
    public HubLabelOnecharacterTwoDifferenttypes = "";
    public HubLabelOnecharacterThreeDifferentTypes = "";
    public HubLabelOnecharacterFOURDifferenttypes = "";
    public HubLabelLastSynchronisation = "";
    public HubLabelProvideLastSynchronisation = "";
    public HubLabelProvideStatus = "";
    public HubLabelIsSyncNowLabel = "";
    public HubLabeShowError = "";
    public HubLabelTokenErorr = "";
    public HubLabelChangePassword = "";
    public HubLabelDiagnostics = "";
    public HubLabelOriginalPassword = "";
    public HubLabelNewPassword = "";
    public HubLabelReType = "";
    public HubLabelOk = "";
    public HubLabelNewDevice = "";
    public HubLabelSlider = "";
    public HubLabelTextIndicatorSlider = "";
    public HubLabelNumericIndicator = "";
    public HubLabelTextIndicator = "";
    public HubLabelIncorrectEmailError = "";
    public HubLabelEnterPasswordMessage = "";
    public HubLabelEnterEmailMessage = "";
    public HubLabelProvideAVSourceScenes = "";
    public HubLabelProvideLightScenes = "";
    public HubLabelProvideGatewayl = "";
    public HubLabelProvideCateringSystemSpace = "";
    public HubLabelProvideBookingSystemSpace = "";
    public HubLabelProvideSensorId = "";
    public HubLabelProvideAreaId = "";
    public HubLabelCleanThreshold = "";
    public HubLabelSignsofLifeThreshold = "";
    public HubLabelMaxBookingDuration = "";
    public HubLabelMinBookingDuration = "";
    public HubLabelProvideSize = "";
    public HubLabelProvideCapacity = "";
    public HubLabelProvideMapURI = "";
    public HubLabelProvideLevelNumber = "";
    public HubLabelprovideYposition = "";
    public HubLabelProvideXposition = "";
    public HubLabelProvideCustomInformation = "";
    public HubLabelProvideLatitude = "";
    public HubLabelProvideLongitude = "";
    public HubLabelProvideAddress = "";
    public HubLabelProvideCity = "";
    public HubLabelProvideCountryName = "";
    public HubLabelProvidevalue = "";
    public HubLabelProvideId = "";
    public HubLabelProvideCode = "";
    public HubLabelEnvironmental = "";
    public HubLabelRoot = "";
    public HubLabelRegion = "";
    public HubLabelLand = "";
    public HubLabelBuilding = "";
    public HubLabellevel = "";
    public HubLabelZone = "";
    public HubLabelRoom = "";
    public HubLabelDesk = "";
    public HubLabelManual = "";
    public HubLabelAutomatic = "";
    public HubLabelNotBookable = "";
    public HubLabelReservable = "";
    public HublabelAutoCheckInLabel = "";
    public HublabelCheckInTime = "";
    public HublabelCompletionTime = "";
    public HublabelCancelletionTime = "";
    public HubLabelAlldayBookingsOnly = "";
    public HubLabelNone = "";
    public HubLabelCogniPointRESTJSON = "";
    public HubLabelCogniPointWebhook = "";
    public HubLabelVergesenseRESTJSON = "";
    public HubLabelVergesenseWebhook = "";
    public HubLabelSyneticaMQTT = "";
    public HubLabelgENESIS64WebhookLabel = "";
    public HubLabelPresence = "";
    public HubLabelTrafficLine = "";
    public HubLabelOffice365 = "";
    public HubLabelCondeco = "";
    public HubLabelgENESIS64 = "";
    public HubLabelValues = "";
    public HubLabelScenes = "";
    public HubLabelVisitStart = "";
    public HubLabelVisitEnd = "";
    public HubLabelVisitorEmail = "";
    public HubLabelvisitor = "";
    public HubLabelCompany = "";
    public HubLabelHostName = "";
    public HubLabelFacilityManagementText = "";
    public HubLabelBookings = "";
    public HubLabelTIMESPENT = "";
    public HubMenuBookings = "";
    public HubMenuCatering = "";
    public HubMenuTasks = "";
    public HubMenuVisitors = "";
    public HubLabelTaskTypeName = "";
    public HubLabelProvideTaskTypeName = "";
    public HubLabelTaskTypeId = "";
    public HubLabelProvideTaskTypeIdLabel = "";
    public HubLabelCategoryId = "";
    public HubLabelProvideCategoryId = "";
    public HubLabelProvideSpaceTypeLabel = "";
    public HubLabelResolveDuration = "";
    public HubLabelProvideResolveDuration = "";
    public HubLabelPriorityLabel = "";
    public HubLabelProvidePriority = "";
    public HubLabelPriorityResolveDuration = "";
    public HubLabelProvidePriorityResolveDuration = "";
    public HubLabelDefaultCleanTask = "";
    public HubLabelAddDetails = "";
    public HubLabelEquipmentType = "";
    public HubTabOverview = "";
    public HubMenuOverview = "";
    public HubButtonApprove = "";
    public HubButtonDeny = "";
    public HubButtonCheckIn = "";
    public HubButtonCheckOut = "";
    public HubTabAssigned = "";
    public HubTabUnAssigned = "";
    public HubLabelUnAssignedTask = "";
    public HubLabelOverDewTask = "";
    public HubTabResolved = "";
    public HubButtonResolveMyTaskButton = "";
    public HubLabelTask = "";
    public HubLabeltaskType = "";
    public HubLabelCategory = "";
    public HubLabelLocation = "";
    public HubLabelTimeRemaining = "";
    public HubLabelresolutionTimeLabel = "";
    public HubLabelCreatedBy = "";
    public HubLabelResolver = "";
    public HubLabelDetailslabel = "";
    public HubLabelVisitID = "";
    public HubLabelVisitStartLabelPlaceholder = "";
    public HubLabelVisitEndLabelPlaceholder = "";
    public HubLabelHostNamePlaceholderText = "";
    public HubLabelBookingName = "";
    public HublabelBookingId = "";
    public HublabelExternalId = "";
    public HubLabelBookingStart = "";
    public HubLabelBookingEnd = "";
    public HubLabelSpaceName = "";
    public HubLabelArrivalLocationId = "";
    public HubLabelCancelled = "";
    public HubLabelDeniedLabel = "";
    public HubLabelCheckedIn = "";
    public HubLabelCheckedOut = "";
    public HubLabelNoShow = "";
    public HubMenuNotificationSetup = "";
    public HubLabelVisitsAwaitingApproval = "";
    public HubLabelVisitsScheduledforToday = "";
    public HubLabelVisitsScheduledForNextWorkingDay = "";
    public HubLabelOnSiteCheckedInVisits = "";
    public HubLabelApprovedVisits = "";
    public HubButtonCheckInCap = "";
    public HubButtonCheckOutCap = "";
    public HubLabelWorkInProgressLabel = "";
    public HubLabelComplete = "";
    public HubLabelInvalidEmailError = "";
    public HubLabelOneCharacterError = "";
    public HubLabelSelectBuildingError = "";
    public HubLabelsppSettingsSubMenuTitle = "";
    public HubLabelDataSecuritySubMenuTitle = "";
    public HubLabelOwner = "";
    public HubLabelAttendees = "";
    public HubLabelAddAttendeeLabel = "";
    public HubLabelLocalTime = "";
    public HubLabelEquipmentId = "";
    public HubLabelTasksOverview = "";
    public HubLabelAssignedTasks = "";
    public HubButtonUnassignedTasks = "";
    public HubLabelResolvedTasks = "";
    public HubLabeluserType = "";
    public HubLabeluserLabel = "";
    public HubLabelApplication = "";
    public HubLabelApplicationKey = "";
    public HubLabelBookingsOverview = "";
    public HubLabelBookingsForToday = "";
    public HubLabelBookingsForNextWorkingDay = "";
    public HubLabelBookingsfromPreviousWorkingDay = "";
    public HubLabelBookingsforNext7Days = "";
    public HubLabelBookingsforFilteredList = "";
    public HubLabelBookedFor = "";
    public HubLabelBookedBy = "";
    public HubLabelCompleted = "";
    public HubTabPreviousWorkingDay = "";
    public HubLabelAHU = "";
    public HubLabelCAV = "";
    public HubLabelVAV = "";
    public HubLabelSF = "";
    public HubLabelEF = "";
    public HubLabelTef = "";
    public HubLabelPumpSet = "";
    public HubLabelPump = "";
    public HubLabelphe = "";
    public HubLabelThermalStore = "";
    public HubLabelHeatPump = "";
    public HubLabelBoiler = "";
    public HubLabelCalorifier = "";
    public HubLabelChiller = "";
    public HubLabelCoolingTower = "";
    public HubLabelpu = "";
    public HubLabelfcu = "";
    public HubLabelAirCurtain = "";
    public HubLabeltrenchHeater = "";
    public HubLabelGenerator = "";
    public HubLabelchp = "";
    public HubLabelups = "";
    public HubLabelController = "";
    public HubLabelElectricMeter = "";
    public HubLabelThermalMeter = "";
    public HubLabelGasMeter = "";
    public HubLabelWaterMeter = "";
    public HubLabelFireExtinguisher = "";
    public HubLabelCallPoint = "";
    public HubLabelSmokeDetector = "";
    public HubLabelHeatDetector = "";
    public HubLabelVisualAndSounder = "";
    public HubLabelFireAlarmPanel = "";
    public HubLabelOutdoorUnit = "";
    public HubLabelIndoorUnit = "";
    public HubLabelPresentationAid = "";
    public HubLabelHearingAid = "";
    public HubLabeltvAv = "";
    public HubLabelConfirmation = "";
    public HubLabelDisplayScreen = "";
    public HubLabelCoffeeMachine = "";
    public HubLabelUnableToLoginMessage = "";
    public HubLabelBookingStatus = "";
    public HubLabelCheckinStatus = "";
    public HubLabelCheckOutStatus = "";
    public HubLabelRedirectionStatus = "";
    public HubLabelRedirectionSubtitle_part_1 = "";
    public HubLabelIsDefault = "";
    public HubLabelVisitApprovalStatus = "";
    public HubLabelCheckinCheckoutStatusLabel = "";
    public HubLabelVisitorsOverview = "";
    public HubLabelVisitorLevel = "";
    public HubButtonAssign = "";
    public HubButtonUnAssign = "";
    public HubButtonReallocate = "";
    public HubButtonSetInProgress = "";
    public HubButtonChangePriority = "";
    public HubLabelDefault = "";
    public HubLabelComment = "";
    public HubLabelNoRights = "";
    public HubLabelTaskStatus = "";
    public HubLabelPassFailRate = "";
    public HubLabelUserSelection = "";
    public HubLabelAttendeeSelection = "";
    public HubLabelnameEmailAddress = "";
    public HubLabelSetPriority = "";
    public HubLabelMessageBox = "";
    public HubLabelPrivate = "";
    public HubLabelAutomaticCheckin = "";
    public HubLabelStart = "";
    public HubLabelStartDateVisitFilter = "";
    public HubLabelEndDateVisitFilter = "";
    public HublabelHostVisitFilter = "";
    public HubLabelEnd = "";
    public HubLabelInvalidJSONMessage = "";
    public HubLabelTaskCategory = "";
    public HubLabelMACaddress = "";
    public HubLabelMACaddressPlaceholder = "";
    public HubLabelIPAddressLabel = "";
    public HubLabelIPAddressPlaceholder = "";
    public HubTabDeviceConfiguration = "";
    public HubLabelEventType = "";
    public HubLabelEmailNotification = "";
    public HubLabelPushNotification = "";
    public HubLabelEventTypePlaceholder = "";
    public HubLabelTo = "";
    public HubLabelFrom = "";
    public HubLabelToPlaceholder = "";
    public HubLabelcc = "";
    public HubLabelccPlaceholder = "";
    public HubLabelbccLabel = "";
    public HubLabelbccPlaceholder = "";
    public HubLabelReplyTo = "";
    public HubLabelReplyToPlaceholder = "";
    public HubLabelSubject = "";
    public HubLabelSubjectPlaceholder = "";
    public HubLabelhtmlMessageLabel = "";
    public HubLabelhtmlMessagePlaceholder = "";
    public HubLabelSendEmail = "";
    public HubLabelMessage = "";
    public HubLabelSendMobilePushMessage = "";
    public HubLabelTitle = "";
    public HubLabelShortMessage = "";
    public HubLabelTitlePlaceholder = "";
    public HubLabelMessagePlaceholder = "";
    public HubLabelarea = "";
    public HubLabelareaPlaceholder = "";
    public HubLabelAction = "";
    public HubLabelActionPlaceholder = "";
    public HubLabelBadge = "";
    public HubLabelBadgePlaceholder = "";
    public HubLabelActionURI = "";
    public HubLabelActionURIPlaceholder = "";
    public HubLabelSecurityProviderType = "";
    public HubLabelRule = "";
    public HubLabelRulePlaceholder = "";
    public HubTabNext7Days = "";
    public HubTabFutureVisitors = "";
    public HubLabelHostNamePlaceholder = "";
    public HubLabelHighPriority = "";
    public HubLabelNormalPriority = "";
    public HubLabelLowPriority = "";
    public HubMenuFileManagement = "";
    public HubMenuRoamer = "";
    public HubLabelFilter = "";
    public HubMenuUnity = "";
    public HubMenuInspire = "";
    public HubMenuCommon = "";
    public HubLabelNoneSecurityIcon = "";
    public HubLabelBasicSecurityIcon = "";
    public HubLabelLocalSecurityIcon = "";
    public HubLabelDeepSecurityIcon = "";
    public HubLabelGlobalSecurityIcon = "";
    public HubMenuOperationalServices = "";
    public HubMenuAdminPortal = "";
    public HubMenuoneLens360 = "";
    public HubMenuSelectaBuilding = "";
    public HubTabOpservDirectedCleaning = "";
    public HubMenuOpservDirectedCleaning = "";
    public HubLabelSpaceId = "";
    public HubLabellastOccupiedLabel = "";
    public HubMenuOpservNotifications = "";
    public HubMenumySchedule = "";
    public HubLabelMyBookings = "";
    public HubMenuMyBookings = "";
    public HubLabelMyVisitors = "";
    public HubMenuMyVisitors = "";
    public HubMenuMyBookingsForOthers = "";
    public HubLabelMyTask = "";
    public HubMenuMyTask = "";
    public HubMenuMyTaskstoResolve = "";
    public HubMenuFlex = "";
    public HubLabelOtherBookings = "";
    public HubLabelMyBookingsTableDescription = "";
    public HubTabRecentlyResolved = "";
    public HubTabOverdue = "";
    public HublabelSpaceLayout = "";
    public HubLabelSpaceArrangement = "";
    public HubLabelInProgress = "";
    public HubLabelOccupancyOverviewLabel = "";
    public HubLabelTodaysBookings = "";
    public HubLabelCheckedInBookings = "";
    public HubLabelNoShowBookings = "";
    public HubLabelTodaysVisitors = "";
    public HubLabelVisitorsInBuilding = "";
    public HubLabelVisitorsCheckedOut = "";
    public HubLabelSpacesNotCheckedIn = "";
    public HubLabelSpacesWithNoBookings = "";
    public HubLabelUnOccupiedSpaceswithBookings = "";
    public HubMenuSustainabilityAnalitics = "";
    public HubMenuSpaceAnalytics = "";
    public HubMenuUserPreference = "";
    public HubButtonImport = "";
    public HublabelUploadFile = "";
    public HubLabelFile = "";
    public HubLabelAutoCancelledStatus = "";
    public HubLabelamended = "";
    public HubLabelNew = "";
    public HublabelEarlyCheckIn = "";
    public HublabelLateCheckIn = "";
    public HubButtonHistoricalData = "";
    public HubMenuAccountSettings = "";
    public HubMenuWorkplace = "";
    public HubLabelMySearchLabel = "";
    public HubLabelworkingDay = "";
    public HubLabelSearchResults = "";
    public HubTabPersonal = "";
    public HubLabelVisitorNotifications = "";
    public HubLabelTaskNotifications = "";
    public HubLabelBookingNotifications = "";
    public HubLabelSelectBuilding = "";
    public HubLabelworkspaces = "";
    public HubMenuSearchaSpace = "";
    public HubMenuSpaceAnalyticsmain = "";
    public HubLabelPacs = "";
    public HubButtonPrintPass = "";
    public HubButtonPrint = "";
    public HubLabelOccupancyLabel = "";
    public HubLabelnetworkFailure = "";
    public HubLabelPleaseTryAgain = "";
    public HubLabelUnexpectedResponse = "";
    public HubLabelSomethingWentWrong = "";
    public HubLabelValidationErrors = "";
    public HubLabelPleaseSelectABuilding = "";
    public HubLabelPleaseSelectAFloor = "";
    public HubLabelStartDateMustBeBeforeTheEndDate = "";
    public HubLabelSaved = "";
    public HubLabelPreferencesSaved = "";
    public HubLabelEnterYourPin = "";
    public HubLabelPinLengthError = "";
    public HubLabelLoginFailed = "";
    public HubLabelLogInAgain = "";
    public HubLabelNoPermissionToLogIn = "";
    public HubLabelLoginFailedPleaseTryAgain = "";
    public HubLabelBookForSomeone = "";
    public HubLabelEmployee = "";
    public HubLabelLayouts = "";
    public HubLabelLinkedSpaces = "";
    public HubLabelBookOnBehalfHeading = "";
    public HubLabelChooseBookingSpaceFor = "";
    public HubLabelEnterNameOrEmailOfEmployee = "";
    public HubLabelEmployeeAutoCheckInRoamerAccess = "";
    public HubLabelVisitorAutoCheckInRoamerAccess = "";
    public HubLabelNotMemberOfStaff = "";
    public HubLabelLayoutAndArrangement = "";
    public HubLabelSelectALayout = "";
    public HubLabelSeatingArrangement = "";
    public HubLabelConfirmYourBooking = "";
    public HubButtonBack = "";
    public HubButtonCancelBooking = "";
    public HubLabelViewStatus = "";
    public HubButtonUpdateBooking = "";
    public HubButtonBacklabel = "";
    public HubLabelEditYourBooking = "";
    public HubLabelconfirmCancel = "";
    public HubButtoncancelConfirmation = "";
    public HubLabelalreadyPresent = "";
    public HubLabelInvalidEmailAddress = "";
    public HubLabelNoOnBehalfSelcted = ""
    public HubButtonVisitorAccess = "";
    public HubLabelNameOrEmailLabel = "";
    public HubLabelFirstNamePlaceholderLabel = "";
    public HubLabelLastNamePlaceholder = "";
    public HubLabelCompanyNamePlaceholder = "";
    public HubButtonAddattendeebutton = "";
    public HubLabelVisitorAttendee = "";
    public HubButtonClearAttendees = "";
    public HubLabelemailPlaceholder = "";
    public HubLabelBooking = "";
    public HubLabelAddDescription = ""
    public HubLabelselectALinkedSpaces = "";
    public HubButtonEditsearchcriteria = "";
    public HubLabelNeeditnow = "";
    public HubLabelAssoonaspossible = "";
    public HubLabelDateandTime = "";
    public HubButtonUpdate = "";
    public HubLabelClearSelections = "";
    public HubButtonBookSpace = "";
    public HubLabelInProgressText = "";
    public HubLabelAny = "";
    public HubLabelSelectanOption = "";
    public HubLabelCalendarIntegration = "";
    public HubLabelLocationTracking = "";
    public HubLabelPIN = "";
    public HubLabelDataPrivacy = "";
    public HubLabelManagePIN = "";
    public HubLabelMyDefaultBuilding = "";
    public HubLabelMypreferredFloor = "";
    public HubLabelEndTime = "";
    public HubLabelStartTime = "";
    public HubLabeliconInTheMobileApp = "";
    public HubLabelViaPushNotification = "";
    public HubLabelViaEmailNotification = "";
    public HubLabelBuildingSpecificPushNotifications = "";
    public HubLabelDataHasBeenSavedSuccessfully = "";
    public HubLabelSustainabilityAnalyticsFloors = "";
    public HubLabelSizeText = "";
    public HubLabelTemperature = "";
    public HubLabelHumidity = "";
    public HubLabelSustainabilityAnalyticsSensors = "";
    public HubLabelNoise = "";
    public HubLabelLastUpdate = "";
    public HubLabelCo = "";
    public HubLabelCO2 = "";
    public HubLabelPM10 = "";
    public HubLabelPM25 = "";
    public HubLabelTVOC = "";
    public HubLabelDate = "";
    public HubLabelSustainabilityAnalyticsZones = "";
    public HubLabelSustainabilityAnalyticsOverview = "";
    public HubLabelNoiseLevel = "";
    public HubLabelAirQuality = "";
    public HubLabelGood = "";
    public HubLabelClass = "";
    public HubLabelSubClass = "";
    public HubLabelExpiry = "";
    public HubLabelNeverApproved = "";
    public HubLabeSelectFile = "";
    public HubLabelCheckinBooking = "";
    public HubLabelBookingCheckInSuccessfully = "";
    public HubLabelBookingCheckOutSuccessfully = "";
    public HubLabelFacilityManagement = "";
    public HubLabelSetyourpreferences = "";
    public HubLabelSetUserPrefenceError = "";
    public HubLabelCheckOutBooking = "";
    public HubLabelNotCheckedIn = "";
    public HublabelEarlyCheckInText = "";
    public HubLabelEarlyFinish = "";
    public HubLabelAutoComplete = "";
    public HubLabelAvailable = "";
    public HubLabelPassed = "";
    public HubLabelFailed = "";
    public HubLabelFair = "";
    public HubLabelPoor = "";
    public HubLabelNoData = "";
    public HubLabelSuccess = "";
    public HubLabelError = "";
    public HubLabelRequestNewPassword = "";
    public HubLabelSubmitRequest = "";
    public HubLabelCateringDeletedSuccessfully = "";
    public HubLabelNotificationsDeleted = "";
    public HubLabelFileuploadedsuccessfully = "";
    public HubLabelvisitissuccessfullycancelled = "";
    public HubLabelUsingTheListView = "";
    public HubmapFailedToLoad = "";
    public HubLabelSelectAFloor = "";
    public HubLabelmapView = "";
    public HubLabelNoSpacesAvailable = "";
    public HubLabelbackToResults = "";
    public HubLabelsearchCriteriaNoSpaces = "";
    public HubLabelSetUp = "";
    public HubLabelNavigateWorkspaces = "";
    public HubLabelDoThis = "";
    public HubLabelDoItLater = "";
    public HubLabelPreferences = "";
    public HubLabelUserPreferencesMenu = "";
    public HubLabelBuildingPreference = "";
    public HubLabelBuildingPreferenceParagraph = "";
    public HubLabelBuildingText = "";
    public HubLabelNotified = "";
    public HubLabelNotifiedParagraph = "";
    public HubLabelVisitorArrivals = "";
    public HubLabelBookingReminders = "";
    public HubLabelServiceRequest = "";
    public HubLabelDontWorry = "";
    public HubLabelColleagueFinding = "";
    public HubLabelColleagueFindingParagraph = "";
    public HubLabelBookingData = "";
    public HubLabelYourfloorpreference = "";
    public HubLabelfloorpreferenceParagraph = "";
    public HubLabelFloortext = "";
    public HubLabelSetupaPIN = "";
    public HubLabelSetupaPINPara = "";
    public HubLabel4DigitPIN = "";
    public HubLabelPINMenu = "";
    public HubLabelYourWorkingDay = "";
    public HubLabelWorkingHrs = "";
    public HubLabelStarttext = "";
    public HubLabelEndtext = "";
    public HubLabelMenu = "";
    public HubTitleTailorNotification = "";
    public HubSubTitleTailorNotification = "";
    public HubSubTitleSecondTailorNotification = "";
    public HubLabelVisitorNotification = "";
    public HubLabelTaskNotification = "";
    public HubLabelBookingNotification = "";
    public HubLabelBuildingSpecific = "";
    public HubLabelBellIconInRoamer = "";
    public HubLabelPushNotifications = "";
    public HubLabelEmailNotifications = "";
    public HubSubTitleBuildingNotification = "";
    public HubSubtitleworkplace = "";
    public HubTitleWorkingDay = "";
    public HubSubTitleWorkingDay = "";
    public HubTitleSearchResult = "";
    public HubSubTitleSearchResult = "";
    public HubButtonList = "";
    public HubButtonMap = "";
    public HubTitleCalendar = "";
    public HubSubTitleCalendar = "";
    public HubLabelSynchronise365 = "";
    public HubTitleDataPrivacy = "";
    public HubSubTitleDataPrivacy = "";
    public HubTitleManagePin = "";
    public HubSubTitleManagePin = "";
    public HubSubTitleSecondManagePin = "";
    public HubLabelYourPinWillNeedToBeA4DigitCode = "";
    public HubButtonSetPin = "";
    public HubLabelSpaceoverview = "";
    public HubTabResolved7days = "";
    public HubLabelAssigned = "";
    public HubLabelUnassigned = "";
    public HubLabelResolved = "";
    public HubLabelReAllocation = "";
    public HubMenuMyNotifications = "";
    public HubLabelSpaceFeatures = "";
    public HubLabelNeedAudioVisual = "";
    public HubLabelNeedPresentationAids = "";
    public HubLabelNeedHearingAids = "";
    public HubLabelNeedCatering = "";
    public HubLabelNeedLinkedSpace = "";
    public HubLabelNeedLayouts = "";
    public HubLabelHowManyPeople = "";
    public HubLabelHistoricalData = "";
    public HublabelResults = "";
    public HublabelSpaceAnalyticsOverview = "";
    public HublabelSpaceUtilization = ""
    public HublabelKpitaget = "";
    public HublabelEqual = "";
    public HublabelCapacityUtilization = "";
    public HublabelNoofSpaces = "";
    public HublabelChange = "";
    public HublabelMaxOccupancy = "";
    public HublabelAvailableCapacity = "";
    public HublabelAvgOccupancy = "";
    public HublabelClassType = "";
    public HublabelWorkSpaceLabel = "";
    public HublabelBookableLabel = "";
    public HublabelPeriodType = "";
    public HublabelPeriodStartDate = "";
    public HublabelGroupType = "";
    public HublabelGroupBy = "";
    public HublabelDay = "";
    public HublabelWeek = "";
    public HublabelMonth = "";
    public HublabelYear = "";
    public HublabelSupportSpaces = "";
    public HublabelAmenities = "";
    public HublabelFloor = "";
    public HublabelWorkspaceType = "";
    public HubLabelAllSpaces = "";
    public HubLabelAllBookableSpaces = "";
    public HubLabelAllReservableSpaces = "";
    public HubLabelAllBookandReservableSpaces = "";
    public HublabelSimilartolast = "";
    public HubLabelPrivacyPolicy = "";
    public HubLabelCopyRight = "";
    public HubMenuAnalytics = "";
    public HubMenuSpaceDetails = "";
    public HubMenuHeatMaps = "";
    public HubLabelNoNotification = "";
    public HubLabelNewNotification = "";
    public HubLabelAnnouncements = "";
    public HubLabelFindOut = "";
    public HubLabelYourNotifications = "";
    public HublabelSpaceAnalyticsHeatmaps = "";
    public HublabelAddResources = "";
    public HublabelBooked = "";
    public HublabelSummary = "";
    public HublabelMyBooking = "";
    public HubLabelAll = "";
    public HubLabelUnRead = "";
    public HubLabelYouDone = "";
    public HubLabelNoEquipment = "";
    public HubLabelNoEquipmentPara = "";
    public HubLabelNoBookedNow = "";
    public HubLabelNoBookedNowPara = "";
    public HubLabelAllBooked = "";
    public HubLabelAllBookedPra1 = "";
    public HubLabelAllBookedPra2 = "";
    public HubButtondoThisLater = "";
    public HubLabelAvailabelconfirmation = "";
    public HubLabelHearingConfirmation = "";
    public HubLabelUtilisation = "";
    public HublabelPeakUtilization = "";
    public HublabelViewAll = "";
    public HublabelWork = "";
    public HublabelSupport = "";
    public HublabelAmenity = "";
    public HublabelSpaceClassification = "";
    public HublabelPeak = "";
    public HublabelOutOfService = "";
    public HublabelInUse = "";
    public HublabelCouldBeFree = "";
    public HubLabelSpaceMain = "";
    public HubLabelOccupancyKPI = "";
    public HubLabelWhereIsThisSapce = "";
    public HubLabelHowisthespaceused = "";
    public HublabelOnLast = "";
    public HubLabelSimilar = "";
    public HubLabelUserPrefNotifications = "";
    public HubLabelUserPrefWorkplace = "";
    public HubLabelUserPrefAccountsettings = "";
    public HubLabelUtilization = "";
    public HubLabelAnonymous = "";
    public HubNoBookingDataMessage = "";
    public HubLabelViewBookingPoliciesTableDescription = "";
    public HubMenuHome = ""; //Home
    public HubMenuSetup = ""; //Setup
    public HubMenuSecuritySetup = ""; //Security setup for system
    public HubMenuManage = ""; //Manage
    public HubMenuBookingPolicies = ""; //Booking Policies
    public HubLabelPolicyInformation = "";
    public HubLabelPolicyInformationText = "";
    public HubLabelPolicyName = "";
    public HubLabelPolicyDescription = "";
    public HubLabelPolicyId = "";
    public HubLabelSlots = "";
    public HubLabelSlotsInformationText = "";
    public HubLabelExcludedDates = "";
    public HubLabelExcludedDatesInformationText = "";
    public HubLabelDurationLimits = "";
    public HubLabelDurationLimitsInformationText = "";
    public HubLabelBehaviours = "";
    public HubLabelBehavioursInformationText = "";
    public HubLabelApprovals = "";
    public HubLabelApprovalsInformationText = "";
    public HubLabelMinutes = "";
    public HubLabelFixedTimeLimits = "";
    public HubLabelConfigurableLimits = "";
    public HubLabelMinimumDuration = "";
    public HubLabelMaximumDuration = "";
    public HubLabelRepeatingStartAndEndInterval = "";
    public HubLabelRepeatingEndInterval = "";
    public HubLabelSpecificEndInterval = "";
    public HubLabelSpecificStartandEndInterval = "";
    public HubLabelRepeatingStartInterval = "";
    public HubLabelEvery = "";
    public HubLabelAutoCheckInSubText = "";
    public HubLabelAutoCheckIn = "";
    public HubLabelEarlyCheckInThreshold = "";
    public HubLabelAutoCancelSubText = "";
    public HubLabelAutoCancelBookings = "";
    public HubLabelCancellationThreshold = "";
    public HubLabelBookingHorizonSubText = "";
    public HubLabelBookingHorizonMinimum = "";
    public HubLabelBookingHorizonMaximum = "";
    public HubLabelDays = "";
    public HubLabelApprovalRequired = "";
    public HubLabelAutoRejectionAfter = "";
    public HubLabelMonday = "";
    public HubLabelTuesday = "";
    public HubLabelWednesday = "";
    public HubLabelThursday = "";
    public HubLabelFriday = "";
    public HubLabelSaturday = "";
    public HubLabelSunday = "";
    public HubLabelFromDate = "";
    public HubLabelToDate = "";
    public HubLabelFromTime = "";
    public HubLabelToTime = "";
    public HubLabelBookingPolicyNameError = "";
    public HubLabelBookingPolicyDescription = "";
    public HubLabelBookingPolicyOverlappingSlots = "";
    public HubLabelBookingPolicyInvalidTimeSlotsError = "";
    public HubLabelBookingPolicyMinDurationError = "";
    public HubLabelBookingPolicyMaxDurationError = "";
    public HubLabelBookingPolicyEarlyCheckinError = "";
    public HubLabelBookingPolicyAutoCancelError = "";
    public HubLabelBookingPolicyAutoRejectionError = "";
    public HubLabelBookingPolicyResolveErrorsMessage = "";
    public HubMenuRolesDataSecurity = "";
    public HubMenuSetupClient = "";
    public HubMenuBookingsForOthers = ""; //Bookings for Others
    public HubMenuTasksRaised = ""; // Tasks Raised
    public HubMenuTasksToResolve = ""; // Tasks to Resolve
    public HubMenuSettings = ""; // Settings
    public HubMenuWorkplacePreferences = ""; // Workplace Preferences
    public HubMenuOperations = "";
    public HubMenuSustainability = "";
    public HubMenuSpace = "";
    public HubLabelViewPoliciesTableColumnPolicyName = "";
    public HubLabelViewPoliciesTableColumnDescription = "";
    public HubLabelViewPoliciesTableColumnWorkspaces = "";
    public HubLabelAddButton = "";
    public HubLabelAssignSpacesButton = "";
    public HubLabelAssignPolicy = "";
    public HubLabelAssignPolicySubText = "";
    public HubLabelAssignPolicyFilterText = "";
    public HubLabelSearch = "";
    public HubLabelWarning = ""
    public SpecificTimesWarningMessage = "";
    public SpecificTimesWarningMessageFurtherInfo = "";
    public HubLabelToday = "";
    public HubLabelCustom = "";
    public HubLabelApprove = "";
    public HubLabelReject = "";
    public HubMenuCateringOrders = "";
    public HubMenuCateringItems = "";
    public HubLabelOrders = "";
    public HubButtonResolve = "";
    public HubButtonInProgress = "";
    public HubLabelServiceTime = "";
    public HubLabelCleaningTime = "";
    public HubLabelTaskOwner = "";
    public HubLabelCateringCost = "";
    public HubLabelHost = "";
    public HubLabelAtt = "";
    public HubLabelNotes = "";
    public HubLabelTaskId = "";
    public HubLabelTomorrow = "";
    public HubLabelThisWeek = "";
    public HubLabelNextWeek = "";
    public HubLabelThisMonth = "";
    public HubLabelNextMonth = "";
    public HubLabelDeletePolicy = "";
    public HubLabelDeletePolicyTextPt1 = "";
    public HubLabelDeletePolicyTextPt2 = "";
    public HubLabelDeletePolicyPluralisedTextPt1 = "";
    public HubLabelDeletePolicyTextPluralisedPt2 = "";
    public HubLabelPickPolicy = "";
    public HubLabelDeletePolicyFooterText = "";
    public HubLabelConfirmDeletePolicy = "";
    public HubLabelConfirmDeletePolicyText = "";
    public HubLabelAddYourEquipment = "";
    public HubLabelItemsSelected = "";
    public HubLabelEarlyCheckinThresholdText = "";
    public HubLabelEndBehavioursInformationText = "";
    public HubLabelBookingsApprovedSuccesfullyMsg = "";
    public HubLabelBookingsRejectedSuccesfullyMsg = "";
    public HubLabelItems = "";
    public HubLabelIBSSStandardCostCode = "";
    public HubLabelClientCode = "";
    public HubLabelDepartmentCode = "";
    public HubLabelCostCode = "";
    public HubLabelCostCodes = "";
    public HubLabelCodeType = "";
    public HubMessageNarrowResults = "";
    public HubLabelCostCodeApportioning = "";
    public HubMessageCostsAddUpTo100 = "";
    public HubLabelUpdate = "";
    public HubLabelDueDate = "";
    public HubLabelEnable = "";
    public HubLabelDisable = "";
    public HubLabelTrue = "";
    public HubLabelFalse = "";
    public HubCateringFilterSubTitle = "";
    public HubLabelSet = "";
    public HubLabelFixedDurationLimits = "";
    public HubLabelSpecificStartIntervals = "";
    public HubLabelSpecificEndIntervals = "";
    public HubLabelInclude = "";
    public HubLabelApprovalRoles = "";
    public HubLabelApprovalGroupError = "";
    public HubLabelModalConfirmTitle = "";
    public HubLabelCancelAllMessage = "";
    public HubLabelRejectAllMessage = "";
    public HubLabelFlexSearchCriteriaNoSpaces = "";
    public HubLabelSelectLinkedSpace = "";
    public HubLabelAvailabelCatering = "";
    public HubLabelCodes = "";
    public HubLabelCateringTask = "";
    public HubLabelCancelChanges = "";
    public HubLabelCateringDetailsErrorMsg = "";
    public HubLabelCateringDetailsDescription = "";
    public HubLabelService = "";
    public HubLabelCleanUp = "";
    public HubLabelCutOffTime = "";
    public HubLabelAddNotes = "";
    public HubLabelEditCateringTaskModalSubtext = "";
    public HubLabelOriginal = "";
    public HubLabelQty = "";
    public HubLabelSaveChanges = "";
    public HubLabelServiceTimeGreaterThanCleanUpTimeMsg = "";
    public HubLabelItem = "";
    public HubLabelHours = "";
    public HubLabelConfirmOrder = "";
    public HubLabelEditCatering = "";
    public HubLabelCancelCatering = "";
    public HubLabelBackToBooking = "";
    public HubLabelSwitchToDarkMode = "";
    public HubLabelSwitchToLightMode = "";
    public HubLabelViewProfile = "";
    public HubLabelLogout = "";
    public HubLabelWhatsNew = "";
    public HubMessageEndTimeMustBeAfterStartTime = "";
    public HubMessageStartDateMustBeSameAsEndDate = "";
    public HubMessageStartTimeMinutesMustBeDefinedInBuildingConfiguration = "";
    public HubMessageEndTimeMinutesMustBeDefinedInBuildingConfiguration = "";
    public HubMessageFromIsNotValid = "";
    public HubMessageToIsNotValid = "";
    public HubLabelRecurringBookingPolicySubText = "";
    public HubLabelRecurringBookingErrorText = "";
    public HubLabelRecurringBookingHelperText = "";
    public HubLabelAllowRecurringBookings = "";
    public HubLabelRecurringBookingThreshold= "";
    public HubLabelContinueToLogin = "";
    public HubLabelForgottenPassword = "";
    public HubLabelEmail = "";
    public HubLabelPassword = "";
    public HubLabelLogin = "";
    public HubLabelWelcomeToHub = "";
    public HubLabelYourPortal = "";
    public HubLabelThreeProducts = "";
    public HubLabelRecurringBookings = "";
    public HubLabelCriteria = "";
    public HubLabelCriteriaSubHeading = "";
    public HubLabelFloor = "";
    public HubLabelSingleBooking = "";
    public HubLabelRecurringBooking = "";
    public HubLabelRecurrencePattern = "";
    public HubLabelDaily = "";
    public HubLabelWeekly = "";
    public HubLabelMonthly = "";
    public HubLabelEnds = "";
    public HubLabelOn = "";
    public HubLabelAfter = "";
    public HubLabelOccurrences = "";
    public HubLabelRepeatEvery = "";
    public HubLabelRecurringSpaceDays = "";
    public HubLabelEveryWeekday = "";
    public HubLabelOfEvery = "";
    public HubLabelMonths = "";
    public HubLabelThe = "";
    public HubLabelOf = "";
    public HubLabelFirst = "";
    public HubLabelSecond = "";
    public HubLabelThird = "";
    public HubLabelLast = "";
    public HubLabelFeatures = "";
    public HubLabelLastModified = "";
    public HubLabelSource = "";
    public HubLabelSelectASpace = "";
    public HubLabelSelectASpaceSubheading = "";
    public HubLabelNoSpaceOptions = "";
    public HubLabelNoSpaceOptionsSubText = "";
    public HubLabelAvailability = "";
    public HubLabelBestFit = "";
    public HubLabelMustBeAValidDateAndTime = "";
    public HubLabelMinutesMustBe_AllowedMinutes_Etc = "";
    public HubLabelMustNotBeInThePast = "";
    public HubLabelMustBeBeforeTheEndTime = "";
    public HubLabelMustBeOnTheSameDayAsTheEndTime = "";
    public HubLabelMustBeAfterTheStartTime = "";
    public HubLabelMustBeOnTheSameDayAsTheStartTime = "";
    public HubLabelStartTimeMustBeAValidDateAndTime = "";
    public HubLabelStartTimesMinutesMustBe_AllowedMinutes_Etc = "";
    public HubLabelStartTimeMustNotBeInThePast = "";
    public HubLabelStartTimeMustBeBeforeTheEndTime = "";
    public HubLabelStartTimeMustBeOnTheSameDayAsTheEndTime = "";
    public HubLabelEndTimeMustBeAValidDateAndTime = "";
    public HubLabelEndTimesMinutesMustBe_AllowedMinutes_Etc = "";
    public HubLabelEndTimeMustNotBeInThePast = "";
    public HubLabelAddBookingDetail = "";
    public HubLabelBookingDescription = "";
    public HubLabelOnlineMeetingLink = "";
    public HubLabelRecurrenceDates = "";
    public HubLabelAlternative = "";
    public HubLabelPickAlternativeSpace = "";
    public HubLabelAlternativeSpaceText = "";
    public HubLabelUseSpaceSelected = "";
    public HubLabelTime = "";
    public HubLabelSkipOccurrence = "";
    public HubLabelCreateBookings = "";
    public HubLabelCreatingRecurringBookingsText = "";
    public HubLabelCreated = "";
    public HubLabelClose = "";
    public HubLabelTimeZone = "";
    public HubLabelRow = "";
    public HubLabelEntriesUploadedSuccessfully = "";
    public HubLabelIssuesOccured = "";
    public HubLabelEnterYourCriteria = "";
    public HubLabelPickYourCriteria = "";
    public HubLabelAssigningSpacesMessage = "";
    public HubLabelBookingCheckedInSuccessfully = "";
    public HubLabelSilent = "";
    public HubLabelQuiet = "";
    public HubLabelNormal = "";
    public HubLabelCapacityReachedPt1 = "";
    public HubLabelCapacityReachedPt2 = "";
    public HubLabelCategories = "";
    public HubLabelJSONView = "";
    public HubLabelButtonJSONView = "";
    public HubLabelJSONViewDescription = "";
    public HubLabelUserPermissionDescription = "";
    public HubLabelUserPermissionCol1 = "";
    public HubLabelUserPermissionCol2 = "";
    public HubLabelUserPermissionDescriptionCol1 = "";
    public HubLabelUserPermissionDescriptionCol2 = "";
    public HubLabelVisitorList = "";
    public HubLabelVisitorTrends = "";
    public HubLabelBookingList = "";
    public HubLabelBookingTrends = "";
    public HubLabelAllBookings = "";
    public dataCateringMenuItemImage_S = "";
    public dataNotificationShortMessage_S = "";
    public dataCateringMenuItemShortMessage_S = "";
    public dataUserPrefWorkplaceDefBuilding_L = "";
    public dataUserPrefWorkplaceDefFloor_L = "";
    public funcRefreshBookings_S = "";
    public dataNotificationTitle_S = "";
    public dataNotificationClassification_S = "";
    public dataNotificationSubclassification_S = "";
    public dataNotificationPriority_S = "";
    public dataNotificationAvailableFrom_S = "";
    public dataNotificationExpiryDate_S = "";
    public dataNotificationImage_S = "";
    public dataNotificationCustomSubclassification_S = "";
    public funcUserPrefAccountSetPIN_L = "";
    public funcUserPrefAccountConfirmPIN_L = "";
    public funcNotificationsListAll_S = "";
    public funcNotificationsListUnread_S = "";
    public funcOutOfServiceTitle_S = "";
    public funcOutOfServiceDescription_S = "";
    public funcBookingHomeSpace_S = "";
    public funcBookingHomeSpace_L = "";
    public funcBookingHomeSpaceStart_S ="";
    public funcVisitorsHomeView_L = "";
    public funcVisitorsHomeView_S = "";
    public funcBookingHomeView_L = "";
    public funcBookingHomeView_S = "";
    public funcCateringOrdersHomeView_L = "";
    public funcCateringOrdersHomeView_S = "";
    public funcWelcomeToHomeLabel_S = "";
    public funcBookingHomeYourView_L = "";
    public funcBookingHomeYourVisitors_L = "";
    public funcBookingHomePreferences_S = "";
    public funcBookingHomePreferences_L = "";
    public funcBookingHomeViewPreferences_S = "";
    public funcCreateBookingReturnHome_S = "";
}
